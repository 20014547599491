import api from "@/services/api";
import { Promise } from "core-js";

const BASE_URL = "/transaction-inventory";

//#region state
const state = {};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async getJournalInventory() {
    try {
      const response = await api.get(`${BASE_URL}/rr`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getJournalMaterialRequest() {
    try {
      const response = await api.get(`${BASE_URL}/mr`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getJournalHandOver() {
    try {
      const response = await api.get(`${BASE_URL}/ho`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getJournalProductInTransit() {
    try {
      const response = await api.get(`${BASE_URL}/do`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getJournalReturnMr() {
    try {
      const response = await api.get(`${BASE_URL}/rmr`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getJournalRfs() {
    try {
      const response = await api.get(`${BASE_URL}/rfs`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getJournal(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/journal`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteJournal(context, params) {
    try {
      const response = await api.deleted(`${BASE_URL}/journal`, { params: params });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getMaterialRequestDetail(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/mr/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getHandOverDetail(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/ho/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getHpp() {
    try {
      const response = await api.get(`${BASE_URL}/hpp`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getRfsDetail(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/rfs/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
