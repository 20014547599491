<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card flat>
          <v-card-text class="py-0">
            <form>
              <v-text-field
                flat
                outlined
                background-color="white"
                label="Email"
                v-model="form.email"
                class="py-0"
                append-icon="mdi-email"
              ></v-text-field>
              <v-text-field
                flat
                outlined
                background-color="white"
                label="New Password"
                :type="show ? 'password' : 'text'"
                v-model="form.password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (show = !show)"
                class="py-0"
              ></v-text-field>
            </form>
          </v-card-text>
          <v-card-actions class="py-0 px-4">
            <v-btn color="primary" class="py-0" @click="forgotPassword">Request Password</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="py-0" @click="back"
              ><v-icon>mdi-arrow-left</v-icon> Back</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Forgot Password",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      show: true,
    };
  },
  methods: {
    forgotPassword() {
      this.$store.dispatch("auth/forgotPassword", this.form);
    },
    back() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style></style>
