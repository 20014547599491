<template>
  <v-main>
    <slot></slot>
  </v-main>
</template>

<script>
export default {};
</script>

<style></style>
