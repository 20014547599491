import api from "@/services/api";
import { Promise } from "core-js";

const BASE_URL = "/country";

//#region state
const state = {
  countries: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async getAll(context) {
    try {
      const response = await api.get(BASE_URL);
      context.commit("SET_COUNTRIES", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_COUNTRIES(state, payload) {
    state.countries = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
