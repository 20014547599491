import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/setting-tax";

//#region state
const state = {
  settingTaxs: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        route.push({ name: "Detail Setting Tax", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Setting Tax" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context) {
    try {
      const response = await api.get(BASE_URL);
      if (response.status === 200) {
        context.commit("SET_SETTING_TAXS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_SETTING_TAXS(state, payload) {
    state.settingTaxs = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
