<template>
  <v-list-item :to="item.to" :active-class="`primary white--text`">
    <v-list-item-icon>
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title v-text="item.title" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "Item",
  props: {
    item: {
      type: Object,
      default: () => ({
        title: undefined,
        icon: undefined,
        to: undefined,
      }),
    },
  },
};
</script>

<style scoped>
.v-list-item__icon .v-list-group__header__prepend-icon {
  margin-right: 16px !important;
}
</style>
