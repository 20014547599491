import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/banks";

//#region state
const state = {
  listBank: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async dropdown(context) {
    try {
      const response = await api.get(`${BASE_URL}/dropdown`);
      if (response.status === 200) {
        context.commit("SET_LIST_BANK", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        route.push({ name: "Detail Bank", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createAccountNumber(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/account-number`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.post(`${BASE_URL}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Bank" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteAccountNumber(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/account-number/${id}`);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LIST_BANK(state, payload) {
    state.listBank = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
