<template>
  <div class="grey lighten-4 fill-height">
    <AppBar />
    <Sidebar />
    <v-main>
      <v-container fluid>
        <slot></slot>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import AppBar from "@/components/AppBar";

export default {
  components: {
    Sidebar,
    AppBar,
  },
};
</script>

<style></style>
