import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/users";

//#region state
const state = {
  users: [],
  user: {},
  managers: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        route.push({ name: "Detail User", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "User" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context) {
    try {
      const response = await api.get(BASE_URL);
      if (response.status === 200) {
        context.commit("SET_USERS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAllUser(context) {
    try {
      const response = await api.get(`${BASE_URL}/all`);
      if (response.status === 200) {
        context.commit("SET_USERS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        context.commit("SET_USER", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getManager(context) {
    try {
      const response = await api.get(`${BASE_URL}/managers`);
      if (response.status === 200) {
        context.commit("SET_MANAGERS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async requestAccess(context, params) {
    try {
      const response = await api.post(`${BASE_URL}/request/${params.transactionId}`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_MANAGERS(state, payload) {
    state.managers = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
