import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/chart-of-accounts";

//#region state
const state = {
  chartOfAccounts: [],
  chartOfAccount: {},
  dropDownChartOfAccount: [],
  totalPages: 0,
  pph: [],
  accounts: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    context.commit("SET_LOADER", true, { root: true });
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        context.commit("SET_LOADER", false, { root: true });
        route.push({ name: "Detail Chart Of Account", params: { id: response.data } });
      }
    } catch (error) {
      let message = error.response.status;
      context.dispatch(
        "toast",
        {
          type: "error",
          message: `Ada masalah dengan aplikasi, mohon hubungi admin aplikasi (${message})`,
        },
        { root: true }
      );
      context.commit("SET_LOADER", false, { root: true });
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Chart Of Account" });
      }
    } catch (error) {
      return Promise.resolve(error);
    }
  },
  async getAll(context, params) {
    try {
      const response = await api.get(BASE_URL, params);
      if (response.status === 200) {
        context.commit("SET_CHART_OF_ACCOUNTS", response.data.content);
        context.commit("SET_TOTAL_PAGES", response.data.totalPages);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        // context.commit("SET_CHART_OF_ACCOUNT", response.data);
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAR(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/ar/account-number/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAP(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/ap/account-number/${params.id}`, {
        code: params.code,
      });
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPointer(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/pointer/`, params);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDropDownChartOfAccount() {
    try {
      const response = await api.get(`${BASE_URL}/dropdown`);
      // context.commit("SET_DROPDOWN_CHART_OF_ACCOUNT", response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDropDownChartOfAccountForeignExchange() {
    try {
      const response = await api.get(`${BASE_URL}/dropdown/foreign-exchange`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getBanks() {
    try {
      const response = await api.get(`${BASE_URL}/banks`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPph() {
    try {
      const response = await api.get(`${BASE_URL}/pph`);
      // context.commit("SET_PPH", response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAccounts(context, transaction) {
    try {
      const response = await api.get(`${BASE_URL}/all`, { transaction: transaction });
      // context.commit("SET_ALL", response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getOtherAccounts() {
    try {
      const response = await api.get(`${BASE_URL}/other`);
      // context.commit("SET_ALL", response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListAccountGL() {
    try {
      const response = await api.get(`${BASE_URL}/gl`);
      // context.commit("SET_ALL", response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListAccountReportGL() {
    try {
      const response = await api.get(`${BASE_URL}/report/general-ledger`);
      // context.commit("SET_ALL", response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async printExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/print/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async approval(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/approval/${params.id}`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_CHART_OF_ACCOUNTS(state, payload) {
    state.chartOfAccounts = payload;
  },
  SET_CHART_OF_ACCOUNT(state, payload) {
    state.chartOfAccount = payload;
  },
  SET_DROPDOWN_CHART_OF_ACCOUNT(state, payload) {
    state.dropDownChartOfAccount = payload;
  },
  SET_TOTAL_PAGES(state, payload) {
    state.totalPages = payload;
  },
  SET_PPH(state, payload) {
    state.pph = payload;
  },
  SET_ALL(state, payload) {
    state.accounts = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
