import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/purchase/invoices";

//#region state
const state = {
  purchaseInvoices: [],
  lastFormNumber: null,
  purchaseInvoice: {},
  lastPercentage: 0,
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        route.push({ name: "Detail Purchase Invoice", params: { id: response.data } });
      }
      return Promise.resolve(response);
    } catch (error) {
      if (error.response.status === 409) {
        let message = error.response.data;
        context.dispatch("toast", { type: "error", message: message }, { root: true });
      } else {
        context.dispatch(
          "toast",
          { type: "error", message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi" },
          { root: true }
        );
      }
      context.commit("SET_LOADER", false, { root: true });
      return Promise.reject(error);
    }
  },
  async getLastFormNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/form-number`);
      if (response.status === 200) {
        let num = null;
        let date = new Date();
        if (response.data === "New") {
          num = `PI.${date.getFullYear()}.${date.getMonth() + 1}.00001`;
        } else {
          const lastNumber = response.data ? response.data.split(".")[3] : "";
          const ctx = "" + (parseInt(lastNumber) + 1);
          const end = lastNumber.substr(0, lastNumber.length - ctx.length) + ctx;
          num = `PI.${date.getFullYear()}.${date.getMonth() + 1}.${end}`;
        }
        context.commit("SET_LAST_FORM_NUMBER", num);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context) {
    try {
      const response = await api.get(`${BASE_URL}`);
      if (response.status === 200) {
        context.commit("SET_PURCHASE_INVOICES", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getJournal(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/journal/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, purchaseInvoiceId) {
    try {
      const response = await api.get(`${BASE_URL}/${purchaseInvoiceId}`);
      // if (response.status === 200) {
      //   context.commit("SET_PURCHASE_INVOICE", response.data);
      // }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPercentageUpTo(context, purchaseInvoiceId) {
    try {
      const response = await api.get(`${BASE_URL}/percentage-up-to/${purchaseInvoiceId}`);
      if (response.status === 200) {
        context.commit("SET_PERCENTAGE", response.data ? response.data : 0);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
        context.dispatch(
          "toast",
          { type: "success", message: "Success Updated Data" },
          { root: true }
        );
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Purchase Invoice" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getInvoices() {
    try {
      const response = await api.get(`${BASE_URL}/list`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createJournalManual(context, id) {
    try {
      const response = await api.post(`${BASE_URL}/journal/manual/${id}`);
      if (response.status === 200) {
        window.location.reload();
        context.dispatch(
          "toast",
          { type: "success", message: "Success Updated Data" },
          { root: true }
        );
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateTaxDate(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/tax-date`, body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Updated Data" },
          { root: true }
        );
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LAST_FORM_NUMBER(state, payload) {
    state.lastFormNumber = payload;
  },
  SET_PURCHASE_INVOICE(state, payload) {
    state.purchaseInvoice = payload;
  },
  SET_PURCHASE_INVOICES(state, payload) {
    state.purchaseInvoices = payload;
  },
  SET_PERCENTAGE(state, payload) {
    state.lastPercentage = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
