import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/pph";

//#region state
const state = {
  listInvoice: [],
  listPph: [],
  listJournal: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async createPph23(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/23`, body);
      if (response.status === 200) {
        route.push({ name: "Detail PPH 23", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createPph23Code(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/23/code`, body);
      if (response.status === 200) {
        route.push({ name: "PPH 23 Code" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createPph22(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/22`, body);
      if (response.status === 200) {
        route.push({ name: "Detail PPH 22", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createPph22Import(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/22/import`, body);
      if (response.status === 200) {
        route.push({ name: "Detail PPH 22 Import", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createPph4Verse2(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/4/verse/2`, body);
      if (response.status === 200) {
        route.push({ name: "Detail PPH 4 Verse 2", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updatePph23(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/23`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updatePph22(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/22`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updatePph22Import(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/22/import`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updatePph4Verse2(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/4/verse/2`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPph23(context) {
    try {
      const response = await api.get(`${BASE_URL}/23`);
      if (response.status === 200) {
        context.commit("SET_LIST_PPH", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPph23Code(context) {
    try {
      const response = await api.get(`${BASE_URL}/23/code`);
      if (response.status === 200) {
        context.commit("SET_LIST_PPH", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPph22(context) {
    try {
      const response = await api.get(`${BASE_URL}/22`);
      if (response.status === 200) {
        context.commit("SET_LIST_PPH", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPph22Import(context) {
    try {
      const response = await api.get(`${BASE_URL}/22/import`);
      if (response.status === 200) {
        context.commit("SET_LIST_PPH", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPph4Verse2(context) {
    try {
      const response = await api.get(`${BASE_URL}/4/verse/2`);
      if (response.status === 200) {
        context.commit("SET_LIST_PPH", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPph23ById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/23/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPph23CodeById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/23/code/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPph22ById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/22/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPph22ImportById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/22/import/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPph4Verse2ById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/4/verse/2/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSaleInvoice(context) {
    try {
      const response = await api.get(`${BASE_URL}/sale/invoice`);
      if (response.status === 200) {
        context.commit("SET_LIST_INVOICE", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPurchaseInvoice(context) {
    try {
      const response = await api.get(`${BASE_URL}/purchase/invoice`);
      if (response.status === 200) {
        context.commit("SET_LIST_INVOICE", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async queryInvoice(context, type) {
    try {
      const response = await api.get(`${BASE_URL}/query/invoice`, { type: type });
      if (response.status === 200) {
        context.commit("SET_LIST_INVOICE", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getJournal(context) {
    try {
      const response = await api.get(`${BASE_URL}/journal`);
      if (response.status === 200) {
        context.commit("SET_LIST_JOURNAL", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deletePph23(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/23/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deletePph23Code(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/23/code/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deletePph22(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/22/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deletePph22Import(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/22/import/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deletePph4Verse2(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/4/verse/2/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LIST_PPH(state, payload) {
    state.listPph = payload;
  },
  SET_LIST_INVOICE(state, payload) {
    state.listInvoice = payload;
  },
  SET_LIST_JOURNAL(state, payload) {
    state.listJournal = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
