import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/journals";

//#region state
const state = {
  journals: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async getAll(context) {
    try {
      const response = await api.get(`${BASE_URL}/all`);
      if (response.status === 200) {
        context.commit("SET_JOURNALS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async query(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/query`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getExcelJournalQuery(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/query/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "GL Transaction" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getJournalBuffer(context) {
    try {
      const response = await api.get(`${BASE_URL}/buffer`);
      if (response.status === 200) {
        context.commit("SET_JOURNALS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async approval(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/approval`, body);
      if (response.status === 200) {
        route.push({ name: "Approval Journal" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_JOURNALS(state, payload) {
    state.journals = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
