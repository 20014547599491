import api from "@/services/api";
import { Promise } from "core-js";

const BASE_URL = "/check-balance";

//#region state
const state = {};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async getTemplate() {
    try {
      const response = await api.get(`${BASE_URL}/template`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async saveTemplate(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/template`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTemplateForCheckBalance() {
    try {
      const response = await api.get(`${BASE_URL}/create`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getCheckBalance(context, month) {
    try {
      const response = await api.get(BASE_URL, { month: month });
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async saveCheckBalance(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateUser(context, item) {
    try {
      const response = await api.post(`${BASE_URL}/user`, item);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
