import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import auth from "./modules/auth";
import user from "./modules/user";
import currency from "./modules/currency";
import country from "./modules/country";
import chartOfAccount from "./modules/chartOfAccount";
import purchaseDownPayment from "./modules/purchase/purchaseDownPayment";
import purchaseInvoice from "./modules/purchase/purchaseInvoice";
import purchasePayment from "./modules/purchase/purchasePayment";
import purchaseOtherPayment from "./modules/purchase/purchaseOtherPayment";
import purchaseOtherInvoice from "./modules/purchase/purchaseOtherInvoice";
import purchaseReturn from "./modules/purchase/purchaseReturn";
import replacementCost from "./modules/purchase/replacementCost";
import saleReturn from "./modules/sale/saleReturn";
import saleDownPayment from "./modules/sale/saleDownPayment";
import saleInvoice from "./modules/sale/saleInvoice";
import saleProforma from "./modules/sale/saleProforma";
import salePayment from "./modules/sale/salePayment";
import external from "./modules/external";
import kasbonExternal from "./modules/external/kasbonExternal";
import settlementExternal from "./modules/external/settlementExternal";
import report from "./modules/report";
import glTransaction from "./modules/glTransaction";
import generalKasbon from "./modules/kasbon/generalKasbon";
import paymentGeneralKasbon from "./modules/kasbon/paymentGeneralKasbon";
import settlementKasbon from "./modules/kasbon/settlementKasbon";
import paymentSettlementKasbon from "./modules/kasbon/paymentSettlementKasbon";
import driverKasbon from "./modules/kasbon/driverKasbon";
import paymentDriverKasbon from "./modules/kasbon/paymentDriverKasbon";
import settlementDriver from "./modules/kasbon/settlementDriver";
import paymentSettlementDriver from "./modules/kasbon/paymentSettlementDriver";
import accountNumber from "./modules/accountNumber";
import requirementCategory from "./modules/requirementCategory";
import taxNumber from "./modules/taxNumber";
import fixedAsset from "./modules/asset/fixedAsset";
import fixedAssetCategory from "./modules/asset/fixedAssetCategory";
import journal from "./modules/journal";
import giro from "./modules/giro";
import check from "./modules/check";
import billingDO from "./modules/sale/billingDO";
import settingNotification from "./modules/settingNotification";
import settingTax from "./modules/settingTax";
import bank from "./modules/bank";
import bankStatement from "./modules/bank/bankStatement";
import bankGuarantee from "./modules/bank/bankGuarantee";
import insurance from "./modules/bank/insurance";
import wo from "./modules/wo/wo";
import taskTemplate from "./modules/wo/taskTemplate";
import templateBank from "./modules/templateBank";
import bufferInvoice from "./modules/sale/bufferInvoice";
import combinationKasbon from "./modules/kasbon/combinationKasbon";
import pph from "./modules/pph";
import inventory from "./modules/inventory";
import incomeStatement from "./modules/incomeStatement";
import fiscalMonth from "./modules/fiscalMonth";
import checkBalance from "./modules/checkBalance";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import router from "../router/index";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
let socket = null;
let stompClient = null;

export default new Vuex.Store({
  state: {
    baseURL: `http://${window.location.hostname}:5555`,
    drawer: null,
    error: null,
    connected: false,
    messages: [],
    loader: false,
  },
  getters: {
    connected: state => state.connected,
    error: state => state.error,
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_MESSAGES(state, payload) {
      state.messages = payload;
    },
    SET_CONNECTED(state, payload) {
      state.connected = payload;
    },
    PUSH_MESSAGES(state, payload) {
      state.messages.unshift(payload);
    },
    SET_ERROR(state, payload) {
      state.error = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
  },
  actions: {
    toast(context, params) {
      this._vm.$toast.open({
        type: params.type,
        message: params.message,
        position: "top-right",
      });
    },
    toastNotification(context, params) {
      this._vm.$toast.open({
        type: "success",
        message: params.name,
        position: "top-right",
        onClick: () => {
          router.push({ path: params.url });
        },
      });
    },
    connect(context, onConnect) {
      socket = new SockJS(`${context.state.baseURL}/ws`);
      stompClient = Stomp.over(socket);
      stompClient.connect({}, onConnect, error => {
        context.commit("SET_ERROR", error);
        context.commit("SET_CONNECTED", false);
      });
    },
    send(context, params) {
      if (stompClient && stompClient.connected) {
        stompClient.send(params.url, JSON.stringify(params.msg), {});
      }
    },
    disconnect(context) {
      if (stompClient) {
        stompClient.disconnect();
      }
      context.commit("SET_CONNECTED", false);
    },
    SUBSCRIBE_NOTIFICATION(context, url) {
      context.commit("SET_CONNECTED", true);
      stompClient.subscribe(url, tick => {
        context.dispatch("toastNotification", JSON.parse(tick.body));
        context.commit("PUSH_MESSAGES", JSON.parse(tick.body));
      });
    },
  },
  modules: {
    auth,
    user,
    currency,
    country,
    chartOfAccount,
    purchaseDownPayment,
    purchaseInvoice,
    purchasePayment,
    purchaseOtherPayment,
    purchaseOtherInvoice,
    purchaseReturn,
    replacementCost,
    saleDownPayment,
    saleInvoice,
    saleProforma,
    salePayment,
    saleReturn,
    external,
    kasbonExternal,
    settlementExternal,
    glTransaction,
    generalKasbon,
    paymentGeneralKasbon,
    settlementKasbon,
    paymentSettlementKasbon,
    accountNumber,
    requirementCategory,
    taxNumber,
    fixedAsset,
    fixedAssetCategory,
    journal,
    report,
    giro,
    check,
    bank,
    bankStatement,
    bankGuarantee,
    insurance,
    driverKasbon,
    paymentDriverKasbon,
    settlementDriver,
    paymentSettlementDriver,
    settingNotification,
    settingTax,
    billingDO,
    wo,
    taskTemplate,
    templateBank,
    bufferInvoice,
    combinationKasbon,
    pph,
    inventory,
    incomeStatement,
    fiscalMonth,
    checkBalance,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
