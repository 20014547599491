import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/wo";

//#region state
const state = {
  listWo: [],
  listCatalog: [],
  listInvoiceVendor: [],
  listInvoiceCustomer: [],
  listPaymentVendor: [],
  listPaymentCustomer: [],
  listGeneralKasbon: [],
  listDriverKasbon: [],
  listInstallerKasbon: [],
  listEmployee: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        route.push({ name: "Detail Wo", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Wo" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context) {
    try {
      const response = await api.get(BASE_URL);
      if (response.status === 200) {
        context.commit("SET_LIST_WO", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTasks(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/tasks`, params);
      if (response.status === 200) {
        context.commit("SET_LIST_WO", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getCatalog(context) {
    try {
      const response = await api.get(`${BASE_URL}/catalog`);
      if (response.status === 200) {
        context.commit("SET_LIST_CATALOG", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getEmployee(context) {
    try {
      const response = await api.get(`${BASE_URL}/employee`);
      if (response.status === 200) {
        context.commit("SET_LIST_EMPLOYEE", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getInvoiceVendor(context) {
    try {
      const response = await api.get(`${BASE_URL}/invoice-vendor`);
      if (response.status === 200) {
        context.commit("SET_LIST_INVOICE_VENDOR", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getInvoiceCustomer(context) {
    try {
      const response = await api.get(`${BASE_URL}/invoice-customer`);
      if (response.status === 200) {
        context.commit("SET_LIST_INVOICE_CUSTOMER", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPaymentVendor(context) {
    try {
      const response = await api.get(`${BASE_URL}/payment-vendor`);
      if (response.status === 200) {
        context.commit("SET_LIST_PAYMENT_VENDOR", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPaymentCustomer(context) {
    try {
      const response = await api.get(`${BASE_URL}/payment-customer`);
      if (response.status === 200) {
        context.commit("SET_LIST_PAYMENT_CUSTOMER", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getGeneralKasbon(context) {
    try {
      const response = await api.get(`${BASE_URL}/general-kasbon`);
      if (response.status === 200) {
        context.commit("SET_LIST_GENERAL_KASBON", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDriverKasbon(context) {
    try {
      const response = await api.get(`${BASE_URL}/driver-kasbon`);
      if (response.status === 200) {
        context.commit("SET_LIST_DRIVER_KASBON", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getInstallerKasbon(context) {
    try {
      const response = await api.get(`${BASE_URL}/installer-kasbon`);
      if (response.status === 200) {
        context.commit("SET_LIST_INSTALLER_KASBON", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getByEmployeeId(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/employee/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LIST_WO(state, payload) {
    state.listWo = payload;
  },
  SET_LIST_CATALOG(state, payload) {
    state.listCatalog = payload;
  },
  SET_LIST_EMPLOYEE(state, payload) {
    state.listEmployee = payload;
  },
  SET_LIST_INVOICE_VENDOR(state, payload) {
    state.listInvoiceVendor = payload;
  },
  SET_LIST_INVOICE_CUSTOMER(state, payload) {
    state.listInvoiceCustomer = payload;
  },
  SET_LIST_PAYMENT_VENDOR(state, payload) {
    state.listPaymentVendor = payload;
  },
  SET_LIST_PAYMENT_CUSTOMER(state, payload) {
    state.listPaymentCustomer = payload;
  },
  SET_LIST_GENERAL_KASBON(state, payload) {
    state.listGeneralKasbon = payload;
  },
  SET_LIST_DRIVER_KASBON(state, payload) {
    state.listDriverKasbon = payload;
  },
  SET_LIST_INSTALLER_KASBON(state, payload) {
    state.listInstallerKasbon = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
