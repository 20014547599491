<template>
  <v-list-group
    :prepend-icon="item.icon"
    append-icon="mdi-menu-down"
    no-action
    :active-class="`primary white--text`"
  >
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in item.children">
      <item :key="`item-${i}`" :item="child" />
    </template>
  </v-list-group>
</template>

<script>
import Item from "./Item";
export default {
  name: "Sidebar",
  components: {
    Item,
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        title: undefined,
        icon: undefined,
        to: undefined,
      }),
    },
  },
};
</script>

<style scoped>
.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 24px !important;
}
</style>
