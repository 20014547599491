import api from "@/services/api";
import { Promise } from "core-js";

const BASE_URL = "/fiscal-month";

//#region state
const state = {};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async getFiscalMonthByYear(context, year) {
    try {
      const response = await api.get(BASE_URL, { year: year });
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateStatus(context, params) {
    try {
      const response = await api.post(
        `${BASE_URL}/status/${params.id}`,
        {},
        {
          params: { status: params.status },
        }
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async isDateAllowed(context, date) {
    try {
      const response = await api.get(`${BASE_URL}/validate`, { date: date });
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
