<template>
  <v-navigation-drawer
    v-model="drawer"
    mobile-breakpoint="960"
    app
    width="270px"
    class="grey darken-3"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
  >
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-img src="@/assets/img/header.png" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list dense nav>
      <template v-for="(item, i) in items">
        <sub-item v-if="item.children" :key="`group-${i}`" :item="item" />
        <item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Item from "./Item";
import SubItem from "./SubItem";
import menu from "../services/menu";

export default {
  name: "Sidebar",
  components: {
    Item,
    "sub-item": SubItem,
  },
  data() {
    return {
      barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
      // items: [],
    };
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    role() {
      return this.$store.state.auth.userDetail.role;
    },
    items() {
      if (
        this.role === "ROLE_ADMIN" ||
        this.role === "ROLE_FINANCE" ||
        this.role === "ROLE_ACCOUNTING"
      )
        return menu.admin;
      if (this.role === "ROLE_MANAGER") return menu.manager;
      if (this.role === "ROLE_USER") return menu.user;
      return [];
    },
  },
  methods: {},
};
</script>

<style scoped>
.v-list-item {
  padding: 0 8px !important;
}
</style>
