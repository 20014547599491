import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/kasbon/combination";

//#region state
const state = {
  lastFormNumber: null,
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async mergeJournal(context, params) {
    try {
      const response = await api.post(`${BASE_URL}/merge/journals/${params.id}`, params.body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addAdditional(context, params) {
    try {
      const response = await api.post(`${BASE_URL}/additional/${params.id}`, params.body);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getJournal(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/journal`, params);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPrintJournal(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/print/journal/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDetailMergeJournal(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/merge/journals/detail/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getLastFormNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/form-number`);
      if (response.status === 200) {
        context.commit("SET_LAST_FORM_NUMBER", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll() {
    try {
      const response = await api.get(`${BASE_URL}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getMergeJournal(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/merge/journals/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Payment Kasbon Combination" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deletePaymentJournal(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/merge/journals/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async restorePaymentJournal(context, id) {
    try {
      const response = await api.post(`${BASE_URL}/merge/restore/journals/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LAST_FORM_NUMBER(state, payload) {
    state.lastFormNumber = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
