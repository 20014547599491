<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card flat>
          <v-card-text class="py-0">
            <form>
              <v-text-field
                flat
                outlined
                background-color="white"
                label="Email"
                v-model="form.email"
                class="py-0"
                append-icon="mdi-email"
                @keyup.enter="login"
              ></v-text-field>
              <v-text-field
                flat
                outlined
                background-color="white"
                label="Password"
                :type="show ? 'password' : 'text'"
                v-model="form.password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (show = !show)"
                class="py-0"
                @keyup.enter="login"
              ></v-text-field>
            </form>
          </v-card-text>
          <v-card-actions class="py-0 px-4">
            <v-btn color="primary" class="py-0" @click="login">Login</v-btn>
            <v-spacer></v-spacer>
            <router-link :to="{ name: 'Forgot Password' }" class="py-0 text-decoration-none"
              >Forgot Password ?</router-link
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <dialog-error :error="error" :dialog="dialog" @close="close" />
  </v-container>
</template>

<script>
import DialogError from "@/components/DialogError";

export default {
  name: "Login",
  components: {
    "dialog-error": DialogError,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: {},
      dialog: false,
      show: true,
    };
  },
  methods: {
    login() {
      this.$store.dispatch("auth/login", this.form).catch(error => {
        if (error.response.status === 401) {
          this.error = {
            title: "Login Error",
            text: "email or password is wrong",
          };
        }
        this.dialog = true;
      });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
