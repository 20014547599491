import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/purchase/down-payments";

//#region state
const state = {
  purchaseDownPayments: [],
  lastFormNumber: null,
  purchaseDownPayment: {},
  totalDownPayment: [],
  totalAmountDownPayment: 0,
  listDownPayment: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        route.push({ name: "Detail Purchase Down Payment", params: { id: response.data } });
      }
      return Promise.resolve(response);
    } catch (error) {
      if (error.response.status === 409) {
        let message = error.response.data;
        context.dispatch("toast", { type: "error", message: message }, { root: true });
      } else {
        context.dispatch(
          "toast",
          { type: "error", message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi" },
          { root: true }
        );
      }
      context.commit("SET_LOADER", false, { root: true });
      return Promise.reject(error);
    }
  },
  async linkRr(context, params) {
    try {
      const response = await api.post(`${BASE_URL}/link-rr/${params.id}`, params.body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        window.location.reload();
      }
      return Promise.resolve(response);
    } catch (error) {
      if (error.response.status === 409) {
        let message = error.response.data;
        context.dispatch("toast", { type: "error", message: message }, { root: true });
      } else {
        context.dispatch(
          "toast",
          { type: "error", message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi" },
          { root: true }
        );
      }
      context.commit("SET_LOADER", false, { root: true });
      return Promise.reject(error);
    }
  },
  async getLastFormNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/form-number`);
      if (response.status === 200) {
        let num = null;
        let date = new Date();
        if (response.data === "New") {
          num = `PDP.${date.getFullYear()}.${date.getMonth() + 1}.00001`;
        } else {
          const lastNumber = response.data ? response.data.split(".")[3] : "";
          const ctx = "" + (parseInt(lastNumber) + 1);
          const end = lastNumber.substr(0, lastNumber.length - ctx.length) + ctx;
          num = `PDP.${date.getFullYear()}.${date.getMonth() + 1}.${end}`;
        }
        context.commit("SET_LAST_FORM_NUMBER", num);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context) {
    try {
      const response = await api.get(`${BASE_URL}`);
      if (response.status === 200) {
        context.commit("SET_PURCHASE_DOWN_PAYMENTS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getJournal(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/journal/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, purchaseDownPaymentId) {
    try {
      const response = await api.get(`${BASE_URL}/${purchaseDownPaymentId}`);
      // if (response.status === 200) {
      //   context.commit("SET_PURCHASE_DOWN_PAYMENT", response.data);
      // }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTotalDownPayment(context, purchaseOrderIds) {
    try {
      const response = await api.get(`${BASE_URL}/total-percentage/${purchaseOrderIds}`);
      if (response.status === 200) {
        context.commit("SET_TOTAL_DOWN_PAYMENT", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListDownPayment(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/total-percentage/${id}`);
      if (response.status === 200) {
        context.commit("SET_LIST_DOWN_PAYMENT", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTotalAmountDownPayment(context, purchaseOrderIds) {
    try {
      const response = await api.get(`${BASE_URL}/purchase-order/${purchaseOrderIds}/amount`);
      if (response.status === 200) {
        context.commit("SET_TOTAL_AMOUNT_DOWN_PAYMENT", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
        context.dispatch(
          "toast",
          { type: "success", message: "Success Updated Data" },
          { root: true }
        );
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateTaxDate(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/tax-date`, body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Updated Data" },
          { root: true }
        );
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Purchase Down Payment" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createJournalManual(context, id) {
    try {
      const response = await api.post(`${BASE_URL}/journal/manual/${id}`);
      if (response.status === 200) {
        window.location.reload();
        context.dispatch(
          "toast",
          { type: "success", message: "Success Updated Data" },
          { root: true }
        );
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LAST_FORM_NUMBER(state, payload) {
    state.lastFormNumber = payload;
  },
  SET_PURCHASE_DOWN_PAYMENT(state, payload) {
    state.purchaseDownPayment = payload;
  },
  SET_PURCHASE_DOWN_PAYMENTS(state, payload) {
    state.purchaseDownPayments = payload;
  },
  SET_LIST_DOWN_PAYMENTS(state, payload) {
    state.listDownPayment = payload;
  },
  SET_TOTAL_DOWN_PAYMENT(state, payload) {
    state.totalDownPayment = payload;
  },
  SET_TOTAL_AMOUNT_DOWN_PAYMENT(state, payload) {
    state.totalAmountDownPayment = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
