<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Change Password</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>New Password</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  background-color="white"
                  v-model="newPassword"
                  :rules="[
                    v => !!v || 'New Password is required',
                    v => v.length >= 8 || 'New Password must more than 8 character',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          Close
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-change-password",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      newPassword: "",
    };
  },
  methods: {
    save() {
      this.$emit("save", this.newPassword);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
