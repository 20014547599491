<template>
  <v-overlay :value="$store.state.loader">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "loader",
};
</script>

<style></style>
