import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/fixed-assets";

//#region state
const state = {
  fixedAssets: [],
  lastFormNumber: null,
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        route.push({ name: "Detail Fixed Asset", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getLastFormNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/form-number`);
      if (response.status === 200) {
        let num = "";
        let date = new Date();
        if (response.data === "New") {
          num = `FA.${date.getFullYear()}.${date.getMonth() + 1}.00001`;
        } else {
          const lastNumber = response.data ? response.data.split(".")[3] : "";
          const ctx = "" + (parseInt(lastNumber) + 1);
          const end = lastNumber.substr(0, lastNumber.length - ctx.length) + ctx;
          num = `FA.${date.getFullYear()}.${date.getMonth() + 1}.${end}`;
        }
        context.commit("SET_LAST_FORM_NUMBER", num);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context) {
    try {
      const response = await api.get(`${BASE_URL}`);
      if (response.status === 200) {
        context.commit("SET_FIXED_ASSETS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(BASE_URL, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Fixed Asset" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createV2(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/v2`, body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        route.push({ name: "Detail Fixed Asset Internal", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getLastFormNumberV2(context) {
    try {
      const response = await api.get(`${BASE_URL}/v2/form-number`);
      if (response.status === 200) {
        let num = "";
        let date = new Date();
        if (response.data === "New") {
          num = `FAI.${date.getFullYear()}.${date.getMonth() + 1}.00001`;
        } else {
          const lastNumber = response.data ? response.data.split(".")[3] : "";
          const ctx = "" + (parseInt(lastNumber) + 1);
          const end = lastNumber.substr(0, lastNumber.length - ctx.length) + ctx;
          num = `FAI.${date.getFullYear()}.${date.getMonth() + 1}.${end}`;
        }
        context.commit("SET_LAST_FORM_NUMBER", num);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAllV2() {
    try {
      const response = await api.get(`${BASE_URL}/v2`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getByIdV2(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/v2/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteV2(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/v2/${id}`);
      if (response.status === 200) {
        route.push({ name: "Fixed Asset Internal" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateV2(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/v2`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LAST_FORM_NUMBER(state, payload) {
    state.lastFormNumber = payload;
  },
  SET_FIXED_ASSETS(state, payload) {
    state.fixedAssets = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
