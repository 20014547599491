import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/kasbon/general-kasbons";

//#region state
const state = {
  generalKasbons: [],
  listCategory: [],
  listAccountNumber: [],
  lastFormNumber: null,
  lastKasbonNumber: null,
  generalKasbon: {},
  listGeneralKasbon: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        route.push({ name: "Detail General Kasbon", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createKasbonTemplate(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/template`, body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        route.push({ name: "Kasbon Template" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async linkWo(context, params) {
    try {
      const response = await api.post(`${BASE_URL}/link-wo/${params.id}`, params.body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async linkDo(context, params) {
    try {
      const response = await api.post(`${BASE_URL}/link-do/${params.id}`, params.body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getLastFormNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/form-number`);
      if (response.status === 200) {
        let num = "";
        let date = new Date();
        if (response.data === "New") {
          num = `GK.${date.getFullYear()}.${date.getMonth() + 1}.00001`;
        } else {
          const lastNumber = response.data ? response.data.split(".")[3] : "";
          const ctx = "" + (parseInt(lastNumber) + 1);
          const end = lastNumber.substr(0, lastNumber.length - ctx.length) + ctx;
          num = `GK.${date.getFullYear()}.${date.getMonth() + 1}.${end}`;
        }
        context.commit("SET_LAST_FORM_NUMBER", num);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getLastKasbonNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/kasbon-number`);
      if (response.status === 200) {
        context.commit("SET_LAST_KASBON_NUMBER", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context) {
    try {
      const response = await api.get(`${BASE_URL}`);
      if (response.status === 200) {
        context.commit("SET_GENERAL_KASBONS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTemplates() {
    try {
      const response = await api.get(`${BASE_URL}/template`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getKasbonTemplate(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/template/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "General Kasbon" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteKasbonTemplate(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/template/${id}`);
      if (response.status === 200) {
        route.push({ name: "Kasbon Template" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListCategory() {
    try {
      const response = await api.get(`${BASE_URL}/requirement-category`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListAccountNumber(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/account-number/${id}`);
      if (response.status === 200) {
        context.commit("SET_LIST_ACCOUNT_NUMBER", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListGeneralKasbon() {
    try {
      const response = await api.get(`${BASE_URL}/dropdown`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListGeneralKasbonForSettlement() {
    try {
      const response = await api.get(`${BASE_URL}/dropdown/settlement/approved`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListGeneralKasbonForSettlementAll() {
    try {
      const response = await api.get(`${BASE_URL}/dropdown/settlement/all`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async queryKasbonByWo(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/query/wo`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListWo() {
    try {
      const response = await api.get(`${BASE_URL}/wo`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteLinkWo(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/wo/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteLinkDo(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/link-do/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListDo() {
    try {
      const response = await api.get(`${BASE_URL}/do`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LAST_FORM_NUMBER(state, payload) {
    state.lastFormNumber = payload;
  },
  SET_LAST_KASBON_NUMBER(state, payload) {
    state.lastKasbonNumber = payload;
  },
  SET_GENERAL_KASBON(state, payload) {
    state.generalKasbon = payload;
  },
  SET_GENERAL_KASBONS(state, payload) {
    state.generalKasbons = payload;
  },
  SET_LIST_CATEGORY(state, payload) {
    state.listCategory = payload;
  },
  SET_LIST_ACCOUNT_NUMBER(state, payload) {
    state.listAccountNumber = payload;
  },
  SET_LIST_GENERAL_KASBON(state, payload) {
    state.listGeneralKasbon = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
