import axios from "axios";
import route from "../router/index";
import * as qs from "qs";

const instance = axios.create({
  // baseURL: `http://${window.location.hostname}:5555/api`,
  baseURL: "https://accounting-intercon.iotech.my.id/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem("access_token");
      route.replace({ name: "Login" });
    }
    return Promise.reject(error);
  }
);

const get = (url, params = {}, responseType = "json") => {
  return instance.get(url, {
    params: params,
    responseType: responseType,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "comma" });
    },
  });
};

const post = (url, body = {}, config = { headers: {}, responseType: "json", params: {} }) => {
  return instance.post(url, body, config);
};

const put = (url, body = {}, headers = {}) => {
  return instance.put(url, body, headers);
};

const deleted = (url, config) => {
  return instance.delete(url, config);
};

export default {
  get,
  post,
  put,
  deleted,
};
