import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/auth/Login.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  // {
  //   path: "/print-preview",
  //   name: "Print Preview",
  //   component: () => import("../layouts/PrintPreview.vue"),
  //   meta: {
  //     layout: "auth",
  //     requiresAuth: true,
  //   },
  // },
  //#region auth router path
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "auth",
      requiresAuth: false,
    },
    beforeEnter(to, from, next) {
      const authenticatedUser = localStorage.getItem("access_token");
      if (authenticatedUser) {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
    meta: {
      layout: "auth",
      requiresAuth: false,
    },
    beforeEnter(to, from, next) {
      const authenticatedUser = localStorage.getItem("access_token");
      if (authenticatedUser) {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    },
  },
  //#endregion
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/job/dept/template/overview",
    name: "Job Dept Template Overview",
    component: () => import("../views/JobDeptTemplateOverview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/document/integration",
    name: "Project Document Integration",
    component: () => import("../views/ProjectDocumentIntegration.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  //#region Setting Router Path
  {
    path: "/setting/users",
    name: "User",
    component: () => import("../views/setting/users/User.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/users/create",
    name: "Create User",
    component: () => import("../views/setting/users/CreateUser.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/users/:id",
    name: "Detail User",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/setting/users/DetailUser.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/currency",
    name: "Currency",
    component: () => import("../views/setting/currency/Currency.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/currency/create",
    name: "Create Currency",
    component: () => import("../views/setting/currency/CreateCurrency.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/currency/:id",
    name: "Detail Currency",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/setting/currency/DetailCurrency.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/account-number",
    name: "Account Number",
    component: () => import("../views/setting/account-number/AccountNumber.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/account-number/create",
    name: "Create Account Number",
    component: () => import("../views/setting/account-number/CreateAccountNumber.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/account-number/:id",
    name: "Detail Account Number",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/setting/account-number/AccountNumberView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/requirement-category",
    name: "Requirement Category",
    component: () => import("../views/setting/requirement-category/RequirementCategory.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/requirement-category/create",
    name: "Create Requirement Category",
    component: () => import("../views/setting/requirement-category/CreateRequirementCategory.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/requirement-category/:id",
    name: "Detail Requirement Category",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/setting/requirement-category/RequirementCategoryView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/tax-number",
    name: "Tax Number",
    component: () => import("../views/setting/tax-number/TaxNumber.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/tax-number/create",
    name: "Create Tax Number",
    component: () => import("../views/setting/tax-number/CreateTaxNumber.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/tax-number/skip",
    name: "Skip Tax Number",
    component: () => import("../views/setting/tax-number/SkipTaxNumber.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/tax-number/change",
    name: "Change Tax Number",
    component: () => import("../views/setting/tax-number/ChangeTaxNumber.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/npwp",
    name: "Npwp",
    component: () => import("../views/setting/npwp/Npwp.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/npwp/:id",
    name: "Detail Npwp",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/setting/npwp/NpwpView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/giro",
    name: "Giro",
    component: () => import("../views/setting/giro/Giro.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/giro/:id",
    name: "Detail Giro",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/setting/giro/GiroView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/giro/create",
    name: "Create Giro",
    component: () => import("../views/setting/giro/CreateGiro.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/check",
    name: "Check",
    component: () => import("../views/setting/check/Check.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/check/:id",
    name: "Detail Check",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/setting/check/CheckView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/check/create",
    name: "Create Check",
    component: () => import("../views/setting/check/CreateCheck.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/notification",
    name: "Setting Notification",
    component: () => import("../views/setting/notification/SettingNotification.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/notification/:id",
    name: "Detail Setting Notification",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/setting/notification/SettingNotificationView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/notification/create",
    name: "Create Setting Notification",
    component: () => import("../views/setting/notification/CreateSettingNotification.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/tax",
    name: "Setting Tax",
    component: () => import("../views/setting/tax/SettingTax.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/tax/:id",
    name: "Detail Setting Tax",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/setting/tax/SettingTaxView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/tax/create",
    name: "Create Setting Tax",
    component: () => import("../views/setting/tax/CreateSettingTax.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/income-statement",
    name: "Income Statement",
    component: () => import("../views/setting/income-statement/IncomeStatement.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/income-statement/detail/:id",
    name: "Detail Income Statement",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/setting/income-statement/IncomeStatementView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/income-statement/create",
    name: "Create Income Statement",
    component: () => import("../views/setting/income-statement/CreateIncomeStatement.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/vendor",
    name: "Vendor",
    component: () => import("../views/setting/vendor/Vendor.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting/vendor/:id",
    name: "Detail Vendor",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/setting/vendor/VendorView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion

  //#region  Company Router Path
  {
    path: "/company/tax",
    name: "Tax",
    component: () => import("../views/company/tax/Tax.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/company/tax/create",
    name: "Create Tax",
    component: () => import("../views/company/tax/CreateTax.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/company/tax/:id",
    name: "Detail Tax",
    component: () => import("../views/company/tax/DetailTax.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion

  //#region General Ledger Router Path
  {
    path: "/general-ledger/budget",
    name: "Budget",
    component: () => import("../views/general-ledger/budget/Budget.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/budget/create",
    name: "Create Budget",
    component: () => import("../views/general-ledger/budget/CreateBudget.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/budget/:id",
    name: "Detail Budget",
    component: () => import("../views/general-ledger/budget/DetailBudget.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/chart-of-accounts",
    name: "Chart Of Account",
    component: () => import("../views/general-ledger/chart-of-accounts/ChartOfAccount.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/chart-of-accounts/create",
    name: "Create Chart Of Account",
    component: () => import("../views/general-ledger/chart-of-accounts/CreateChartOfAccount.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/chart-of-accounts/:id",
    name: "Detail Chart Of Account",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/general-ledger/chart-of-accounts/DetailChartOfAccount.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/chart-of-accounts/print-preview",
    name: "Print Chart Of Account",
    props: route => {
      return { size: Number(route.query.size) };
    },
    component: () => import("../views/general-ledger/chart-of-accounts/PrintChartOfAccount.vue"),
    meta: {
      requiresAuth: true,
      layout: "auth",
    },
  },
  {
    path: "/general-ledger/employee-payroll",
    name: "Employee Payroll",
    component: () => import("../views/general-ledger/employee-payroll/EmployeePayroll.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/employee-payroll/create",
    name: "Create Employee Payroll",
    component: () => import("../views/general-ledger/employee-payroll/CreateEmployeePayroll.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/employee-payroll/:id",
    name: "Detail Employee Payroll",
    component: () => import("../views/general-ledger/employee-payroll/DetailEmployeePayroll.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/transaction",
    name: "GL Transaction",
    component: () => import("../views/general-ledger/gl-transaction/GLTransaction.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/transaction/create",
    name: "Create GL Transaction",
    component: () => import("../views/general-ledger/gl-transaction/CreateGLTransaction.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/transaction/:id",
    name: "Detail GL Transaction",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/general-ledger/gl-transaction/GLTransactionView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/transaction/journal/:id",
    name: "Print Preview Journal GL Transaction",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/general-ledger/gl-transaction/PrintPreviewGlTransaction.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/journal",
    name: "Journal",
    component: () => import("../views/general-ledger/journal/Journal.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/query/journal",
    name: "Query Journal",
    component: () => import("../views/general-ledger/journal/QueryJournal.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/journal/:id",
    name: "Detail Journal",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/general-ledger/journal/JournalView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-ledger/approval/journal",
    name: "Approval Journal",
    component: () => import("../views/general-ledger/journal/ApprovalJournal.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion

  //#region Cash & Bank Router Path
  {
    path: "/cash-and-bank/bank-reconcile",
    name: "Bank Reconcile",
    component: () => import("../views/cash-and-bank/bank-reconcile/BankReconcile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cash-and-bank/bank-reconcile/create",
    name: "Create Bank Reconcile",
    component: () => import("../views/cash-and-bank/bank-reconcile/CreateBankReconcile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cash-and-bank/bank-reconcile/:id",
    name: "Detail Bank Reconcile",
    component: () => import("../views/cash-and-bank/bank-reconcile/DetailBankReconcile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bank/bank-statement",
    name: "Bank Statement",
    component: () => import("../views/cash-and-bank/bank-statement/BankStatement.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bank/bank-statement/create",
    name: "Create Bank Statement",
    component: () => import("../views/cash-and-bank/bank-statement/CreateBankStatement.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bank/bank-statement/:id",
    name: "Detail Bank Statement",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/cash-and-bank/bank-statement/BankStatementView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bank/bank-guarantee",
    name: "Bank Guarantee",
    component: () => import("../views/cash-and-bank/bank-guarantee/BankGuarantee.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bank/bank-guarantee/create",
    name: "Create Bank Guarantee",
    component: () => import("../views/cash-and-bank/bank-guarantee/CreateBankGuarantee.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bank/bank-guarantee/:id",
    name: "Detail Bank Guarantee",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/cash-and-bank/bank-guarantee/BankGuaranteeView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bank/general",
    name: "Bank",
    component: () => import("../views/cash-and-bank/bank/Bank.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bank/general/create",
    name: "Create Bank",
    component: () => import("../views/cash-and-bank/bank/CreateBank.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bank/general/:id",
    name: "Detail Bank",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/cash-and-bank/bank/BankView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cash-and-bank/bank-transfer",
    name: "Bank Transfer",
    component: () => import("../views/cash-and-bank/bank-transfer/BankTransfer.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cash-and-bank/bank-transfer/create",
    name: "Create Bank Transfer",
    component: () => import("../views/cash-and-bank/bank-transfer/CreateBankTransfer.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cash-and-bank/bank-transfer/:id",
    name: "Detail Bank Transfer",
    component: () => import("../views/cash-and-bank/bank-transfer/DetailBankTransfer.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cash-and-bank/other-deposit",
    name: "Other Deposit",
    component: () => import("../views/cash-and-bank/other-deposit/OtherDeposit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cash-and-bank/other-deposit/create",
    name: "Create Other Deposit",
    component: () => import("../views/cash-and-bank/other-deposit/CreateOtherDeposit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cash-and-bank/other-deposit/:id",
    name: "Detail Other Deposit",
    component: () => import("../views/cash-and-bank/other-deposit/DetailOtherDeposit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cash-and-bank/other-payment",
    name: "Other Payment",
    component: () => import("../views/cash-and-bank/other-payment/OtherPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cash-and-bank/other-payment/create",
    name: "Create Other Payment",
    component: () => import("../views/cash-and-bank/other-payment/CreateOtherPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cash-and-bank/other-payment/:id",
    name: "Detail Other Payment",
    component: () => import("../views/cash-and-bank/other-payment/DetailOtherPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion

  //#region Sales Router Path
  {
    path: "/sales/sale-down-payment",
    name: "Sale Down Payment",
    component: () => import("../views/sale/sale-down-payment/SaleDownPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-down-payment/create",
    name: "Create Sale Down Payment",
    component: () => import("../views/sale/sale-down-payment/CreateSaleDownPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-down-payment/:id",
    name: "Detail Sale Down Payment",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-down-payment/SaleDownPaymentView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-down-payment/journal/:id",
    name: "Journal Sale Down Payment",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-down-payment/PrintJournalDownPayment.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-down-payment/print/:id",
    name: "Print Preview Sale Down Payment",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-down-payment/PrintPreview.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-down-payment/convert/sell",
    name: "Convert Sale Down Payment",
    component: () => import("../views/sale/sale-down-payment/ConvertDP.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice",
    name: "Sale Invoice",
    component: () => import("../views/sale/sale-invoice/SaleInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice/create",
    name: "Create Sale Invoice",
    component: () => import("../views/sale/sale-invoice/CreateSaleInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice/:id",
    name: "Detail Sale Invoice",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-invoice/SaleInvoiceView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice/journal/:id",
    name: "Journal Sale Invoice",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-invoice/PrintJournalInvoice.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice/print/:id",
    name: "Print Preview Sale Invoice",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-invoice/PrintPreview.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice-po",
    name: "Sale Invoice PO",
    component: () => import("../views/sale/sale-invoice-po/SaleInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice-po/create",
    name: "Create Sale Invoice PO",
    component: () => import("../views/sale/sale-invoice-po/CreateSaleInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice-po/:id",
    name: "Detail Sale Invoice PO",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-invoice-po/SaleInvoiceView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice-po/journal/:id",
    name: "Journal Sale Invoice PO",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-invoice-po/PrintJournalInvoice.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice-po/print/:id",
    name: "Print Preview Sale Invoice PO",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-invoice-po/PrintPreview.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice-bdo",
    name: "Sale Invoice BDO",
    component: () => import("../views/sale/sale-invoice-bdo/SaleInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice-bdo/create",
    name: "Create Sale Invoice BDO",
    component: () => import("../views/sale/sale-invoice-bdo/CreateSaleInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice-bdo/:id",
    name: "Detail Sale Invoice BDO",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-invoice-bdo/SaleInvoiceView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice-bdo/journal/:id",
    name: "Journal Sale Invoice BDO",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-invoice-bdo/PrintJournalInvoice.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-invoice-bdo/print/:id",
    name: "Print Preview Sale Invoice BDO",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-invoice-bdo/PrintPreview.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/invoice/query",
    name: "Sale Invoice Query",
    component: () => import("../views/sale/QueryInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/payment/query",
    name: "Sale Payment Query",
    component: () => import("../views/query/QueryPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-proforma",
    name: "Sale Proforma",
    component: () => import("../views/sale/sale-proforma/SaleProforma.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-proforma/create",
    name: "Create Sale Proforma",
    component: () => import("../views/sale/sale-proforma/CreateSaleProforma.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-proforma/:id",
    name: "Detail Sale Proforma",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-proforma/SaleProformaView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-proforma/print/:id",
    name: "Print Preview Sale Proforma",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-proforma/PrintPreview.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-return",
    name: "Sale Return",
    component: () => import("../views/sale/sale-return/SaleReturn.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-return/create",
    name: "Create Sale Return",
    component: () => import("../views/sale/sale-return/CreateSaleReturn.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-return/:id",
    name: "Detail Sale Return",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-return/SaleReturnView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-return/nota/:id",
    name: "Nota Return",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-return/NotaReturn.vue"),
    meta: {
      requiresAuth: true,
      layout: "auth",
    },
  },
  {
    path: "/sales/sale-payment",
    name: "Sale Payment",
    component: () => import("../views/sale/sale-payment/SalePayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/payment/approval",
    name: "Sale Payment Approval",
    component: () => import("../views/sale/sale-payment/SalePaymentApproval.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-payment/create",
    name: "Create Sale Payment",
    component: () => import("../views/sale/sale-payment/CreateSalePayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-payment/:id",
    name: "Detail Sale Payment",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-payment/SalePaymentView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/sale-payment/print/:id",
    name: "Print Preview Sale Payment",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/sale-payment/VoucherPayment.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/update/remark",
    name: "Update Remark",
    component: () => import("../views/sale/other/UpdateRemark.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/billing-do",
    name: "Billing DO",
    component: () => import("../views/sale/billing-do/BillingDO.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/billing-do/create",
    name: "Create Billing DO",
    component: () => import("../views/sale/billing-do/CreateBillingDO.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/billing-do/:id",
    name: "Detail Billing DO",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/billing-do/BillingDOView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/billing-do/print/:id",
    name: "Print Billing DO",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/billing-do/PrintBillingDO.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/template/bank",
    name: "Template Bank",
    component: () => import("../views/sale/print-invoice/TemplateBank.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/template/bank/create",
    name: "Create Template Bank",
    component: () => import("../views/sale/print-invoice/CreateTemplateBank.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/template/bank/:id",
    name: "Detail Template Bank",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/print-invoice/TemplateBankView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/print/invoice",
    name: "Print Invoice",
    component: () => import("../views/sale/print-invoice/PrintInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/print/invoice/preview",
    name: "Print Invoice Preview",
    component: () => import("../views/sale/print-invoice/PrintInvoicePreview.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/print/invoice-dp/preview",
    name: "Print Invoice Preview DP",
    component: () => import("../views/sale/print-invoice/PrintInvoicePreviewDp.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/print/invoice-proforma/preview",
    name: "Print Invoice Preview Proforma",
    component: () => import("../views/sale/print-invoice/PrintInvoicePreviewProforma.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/print/invoice-huawei/preview",
    name: "Print Invoice Preview Huawei",
    component: () => import("../views/sale/print-invoice/PrintInvoicePreviewHuawei.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/buffer-invoice",
    name: "Buffer Invoice",
    component: () => import("../views/sale/buffer-invoice/BufferInvoice.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/buffer-invoice/:id",
    name: "Detail Buffer Invoice",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/buffer-invoice/BufferInvoiceDetail.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/buffer-invoice/sale/down-payment/:id",
    name: "Create Sale Down Payment With Buffer",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/buffer-invoice/CreateSaleDownPaymentWithBuffer.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/buffer-invoice/sale/invoice/:id",
    name: "Create Sale Invoice With Buffer",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/sale/buffer-invoice/CreateSaleInvoiceWithBuffer.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/cancel/invoice",
    name: "Cancel Invoice",
    component: () => import("../views/sale/CancelInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/link-do/query",
    name: "Query Link DO",
    component: () => import("../views/sale/QueryLinkDO.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sales/do/query",
    name: "Query DO",
    component: () => import("../views/sale/QueryDO.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion

  //#region Purchase Router Path
  {
    path: "/purchases/purchase-down-payment",
    name: "Purchase Down Payment",
    component: () => import("../views/purchases/purchase-down-payment/PurchaseDownPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-down-payment/create",
    name: "Create Purchase Down Payment",
    component: () =>
      import("../views/purchases/purchase-down-payment/CreatePurchaseDownPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-down-payment/:id",
    name: "Detail Purchase Down Payment",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/purchases/purchase-down-payment/PurchaseDownPaymentView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-down-payment/journal/:id",
    name: "Journal Purchase Down Payment",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/purchases/purchase-down-payment/PrintJournalDownPayment.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-invoice",
    name: "Purchase Invoice",
    component: () => import("../views/purchases/purchase-invoice/PurchaseInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-invoice/create",
    name: "Create Purchase Invoice",
    component: () => import("../views/purchases/purchase-invoice/CreatePurchaseInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-invoice/v2/create",
    name: "Create Purchase Invoice V2",
    component: () => import("../views/purchases/purchase-invoice/CreatePurchaseInvoiceV2.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-invoice/v3/create",
    name: "Create Purchase Invoice V3",
    component: () => import("../views/purchases/purchase-invoice/CreatePurchaseInvoiceV3.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-invoice/:id",
    name: "Detail Purchase Invoice",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/purchases/purchase-invoice/PurchaseInvoiceView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-invoice/journal/:id",
    name: "Journal Purchase Invoice",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/purchases/purchase-invoice/PrintJournalInvoice.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/forwarder-invoice",
    name: "Forwarder Invoice",
    component: () => import("../views/purchases/purchase-other-invoice/PurchaseOtherInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/forwarder-invoice/create",
    name: "Create Forwarder Invoice",
    component: () =>
      import("../views/purchases/purchase-other-invoice/CreatePurchaseOtherInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/forwarder-invoice/:id",
    name: "Detail Forwarder Invoice",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () =>
      import("../views/purchases/purchase-other-invoice/PurchaseOtherInvoiceView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/forwarder-payment",
    name: "Forwarder Payment",
    component: () => import("../views/purchases/purchase-other-payment/PurchaseOtherPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/Forwarder-payment/create",
    name: "Create Forwarder Payment",
    component: () =>
      import("../views/purchases/purchase-other-payment/CreatePurchaseOtherPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/forwarder-payment/:id",
    name: "Detail Forwarder Payment",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () =>
      import("../views/purchases/purchase-other-payment/PurchaseOtherPaymentView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/forwarder-payment/voucher/:id",
    name: "Payment Voucher Forwarder",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/purchases/purchase-other-payment/PaymentVoucher.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-return",
    name: "Purchase Return",
    component: () => import("../views/purchases/purchase-return/PurchaseReturn.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-return/create",
    name: "Create Purchase Return",
    component: () => import("../views/purchases/purchase-return/CreatePurchaseReturn.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-return/:id",
    name: "Detail Purchase Return",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/purchases/purchase-return/PurchaseReturnView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-payment",
    name: "Purchase Payment",
    component: () => import("../views/purchases/purchase-payment/PurchasePayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-payment/create",
    name: "Create Purchase Payment",
    component: () => import("../views/purchases/purchase-payment/CreatePurchasePayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-payment/:id",
    name: "Detail Purchase Payment",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/purchases/purchase-payment/PurchasePaymentView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/payment/approval",
    name: "Purchase Payment Approval",
    component: () => import("../views/purchases/purchase-payment/PurchasePaymentApproval.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/payment/confirm/:id",
    name: "Purchase Payment Confirm",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/purchases/purchase-payment/PurchasePaymentConfirm.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/purchase-payment/voucher/:id",
    name: "Payment Voucher Purchase",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/purchases/purchase-payment/PaymentVoucher.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/replacement-cost",
    name: "Replacement Cost",
    component: () => import("../views/purchases/replacement-cost/ReplacementCost.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/replacement-cost/create",
    name: "Create Replacement Cost",
    component: () => import("../views/purchases/replacement-cost/CreateReplacementCost.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/replacement-cost/:id",
    name: "Detail Replacement Cost",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/purchases/replacement-cost/ReplacementCostView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion

  //#region Asset Router Path
  {
    path: "/asset/fixed-asset",
    name: "Fixed Asset",
    component: () => import("../views/asset/fixed-asset/FixedAsset.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset/create",
    name: "Create Fixed Asset",
    component: () => import("../views/asset/fixed-asset/CreateFixedAsset.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset/:id",
    name: "Detail Fixed Asset",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/asset/fixed-asset/FixedAssetView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-category",
    name: "Fixed Asset Category",
    component: () => import("../views/asset/fixed-asset-category/FixedAssetCategory.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-category/create",
    name: "Create Fixed Asset Category",
    component: () => import("../views/asset/fixed-asset-category/CreateFixedAssetCategory.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-category/:id",
    name: "Detail Fixed Asset Category",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/asset/fixed-asset-category/FixedAssetCategoryView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-internal",
    name: "Fixed Asset Internal",
    component: () => import("../views/asset/fixed-asset-internal/FixedAssetInternal.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-internal/create",
    name: "Create Fixed Asset Internal",
    component: () => import("../views/asset/fixed-asset-internal/CreateFixedAssetInternal.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-internal/:id",
    name: "Detail Fixed Asset Internal",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/asset/fixed-asset-internal/FixedAssetInternalView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-edited",
    name: "Fixed Asset Edited",
    component: () => import("../views/asset/fixed-asset-edited/FixedAssetEdited.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-edited/create",
    name: "Create Fixed Asset Edited",
    component: () => import("../views/asset/fixed-asset-edited/CreateFixedAssetEdited.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-edited/:id",
    name: "Detail Fixed Asset Edited",
    component: () => import("../views/asset/fixed-asset-edited/DetailFixedAssetEdited.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-dispotition",
    name: "Fixed Asset Dispotition",
    component: () => import("../views/asset/fixed-asset-dispotition/FixedAssetDispotition.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-dispotition/create",
    name: "Create Fixed Asset Dispotition",
    component: () =>
      import("../views/asset/fixed-asset-dispotition/CreateFixedAssetDispotition.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fixed-asset-dispotition/:id",
    name: "Detail Fixed Asset Dispotition",
    component: () =>
      import("../views/asset/fixed-asset-dispotition/DetailFixedAssetDispotition.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fiscal-fixed-asset-category",
    name: "Fiscal Fixed Asset Category",
    component: () =>
      import("../views/asset/fiscal-fixed-asset-category/FiscalFixedAssetCategory.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fiscal-fixed-asset-category/create",
    name: "Create Fiscal Fixed Asset Category",
    component: () =>
      import("../views/asset/fiscal-fixed-asset-category/CreateFiscalFixedAssetCategory.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/fiscal-fixed-asset-category/:id",
    name: "Detail Fiscal Fixed Asset Category",
    component: () =>
      import("../views/asset/fiscal-fixed-asset-category/DetailFiscalFixedAssetCategory.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/transfer",
    name: "Transfer",
    component: () => import("../views/asset/transfer/Transfer.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/transfer/create",
    name: "Create Transfer",
    component: () => import("../views/asset/transfer/CreateTransfer.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asset/transfer/:id",
    name: "Detail Transfer",
    component: () => import("../views/asset/transfer/DetailTransfer.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion

  //#region Report Router Path
  {
    path: "/report",
    name: "Report",
    component: () => import("../views/report/Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/all-journal",
    name: "All Journal",
    component: () => import("../views/report/AllJournal.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/laba-rugi",
    name: "Report Laba Rugi",
    component: () => import("../views/report/LabaRugi.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/neraca",
    name: "Report Neraca",
    component: () => import("../views/report/Neraca.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/cash-flow",
    name: "Report Cash Flow",
    component: () => import("../views/report/CashFlow.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/perubahan-ekuitas",
    name: "Report Perubahan Ekuitas",
    component: () => import("../views/report/PerubahanEkuitas.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/general-ledger",
    name: "Report General Ledger",
    component: () => import("../views/report/GeneralLedger.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/trial-balance",
    name: "Report Trial Balance",
    component: () => import("../views/report/TrialBalanceV2.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/rasio",
    name: "Report Rasio",
    component: () => import("../views/report/Rasio.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/ar-outstanding/detail",
    name: "Report AR Outstanding Detail",
    component: () => import("../views/report/ArOutstandingDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/ar-outstanding/summary",
    name: "Report AR Outstanding Summary",
    component: () => import("../views/report/ArOutstandingSummary.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/ar/summary",
    name: "Report AR Summary",
    component: () => import("../views/report/ArSummary.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/khpp",
    name: "Report Kartu Harga Pokok Produksi",
    component: () => import("../views/report/KartuHargaPokokProduksi.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/harga-pokok/produksi",
    name: "Report Harga Pokok Produksi",
    component: () => import("../views/report/HargaPokokProduksi.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/grouping-items",
    name: "Report Daftar Grouping Items",
    component: () => import("../views/report/GroupingItems.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/harga-pokok/penjualan",
    name: "Report Harga Pokok Penjualan",
    component: () => import("../views/report/HargaPokokPenjualan.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/biaya-jasa",
    name: "Report Biaya Jasa",
    component: () => import("../views/report/BiayaJasa.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/ap-outstanding/detail",
    name: "Report AP Outstanding Detail",
    component: () => import("../views/report/ApOutstandingDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/ap-outstanding/summary",
    name: "Report AP Outstanding Summary",
    component: () => import("../views/report/ApOutstandingSummary.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/other-ap/detail",
    name: "Report Other AP Detail",
    component: () => import("../views/report/OtherApDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/other-ap/summary",
    name: "Report Other AP Summary",
    component: () => import("../views/report/OtherAccountPayableSummary.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/biaya-dibayar-dimuka",
    name: "Report Daftar Biaya Dibayar Dimuka",
    component: () => import("../views/report/DownPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/persamaan-po",
    name: "Report Persamaan PO",
    component: () => import("../views/report/persamaanPo.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/daftar-aktiva-tetap",
    name: "Report Daftar Aktiva Tetap",
    component: () => import("../views/report/DaftarAktivaTetap.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/nomor-seri/faktur-pajak",
    name: "Report Nomor seri Faktur Pajak",
    component: () => import("../views/report/NomorSeriFakturPajak.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/ppn",
    name: "Report PPN",
    component: () => import("../views/report/Ppn.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/pph",
    name: "Report PPH",
    component: () => import("../views/report/Pph.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/export-daftar-pajak",
    name: "Export Daftar Pajak",
    component: () => import("../views/report/ExportDaftarPajak.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/tanda-terima",
    name: "Tanda Terima",
    component: () => import("../views/report/TandaTerima.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/payment",
    name: "Payment",
    component: () => import("../views/report/Payment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/sales",
    name: "Sales",
    component: () => import("../views/report/Sales.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/bank-statement",
    name: "Report Bank Statement",
    component: () => import("../views/report/BankStatement.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/bank-guarantee",
    name: "Report Bank Guarantee",
    component: () => import("../views/report/BankGuarantee.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/kasbon-installer",
    name: "Report Kasbon Installer",
    component: () => import("../views/report/KasbonInstaller.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/kasbon-outstanding",
    name: "Report Kasbon Outstanding",
    component: () => import("../views/report/KasbonOutstanding.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/settlement-kasbon",
    name: "Report Settlement Kasbon",
    component: () => import("../views/report/SettlementKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/petty-cash",
    name: "Report PettyCash",
    component: () => import("../views/report/PettyCash.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/depreciation",
    name: "Report Depreciation",
    component: () => import("../views/report/Depreciation.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/payment/sale-down-payment",
    name: "Report Payment Sale Down Payment",
    component: () => import("../views/report/PaymentForSaleDownPayment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/currency",
    name: "Report History Currency",
    component: () => import("../views/report/Currency.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/sale/return",
    name: "Report Sale Return",
    component: () => import("../views/report/SaleReturnDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/cash-budget",
    name: "Report Cash Budget",
    component: () => import("../views/report/CashBudgetV2.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/Reval",
    name: "Report Reval",
    component: () => import("../views/report/Reval.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/journals",
    name: "Report Journal",
    component: () => import("../views/report/Journal.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/cogm",
    name: "Report COGM",
    component: () => import("../views/report/COGM.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/cogs",
    name: "Report COGS",
    component: () => import("../views/report/COGS.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/begin-balance",
    name: "Report Begin Balance",
    component: () => import("../views/report/BeginBalance.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/income-statement/monthly",
    name: "Report Income Statement Monthly",
    component: () => import("../views/report/IncomeStatementMonthly.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/revenue/yearly",
    name: "Report Revenue Yearly",
    component: () => import("../views/report/RevenueYearly.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/working-capital",
    name: "Report Working Capital",
    component: () => import("../views/report/WorkingCapital.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/account-payable/tax",
    name: "Report Account Payable Tax",
    component: () => import("../views/report/AccountPayableTax.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/summary/settlement-kasbon",
    name: "Report Summary Settlement Kasbon",
    component: () => import("../views/report/SummarySettlementKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/summary/kasbon",
    name: "Report Summary Kasbon",
    component: () => import("../views/report/SummaryKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/revaluation",
    name: "Report Revaluation",
    component: () => import("../views/report/Revaluation.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/bom/detail",
    name: "Report Bom Detail",
    component: () => import("../views/report/BomDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/inventory/transaction",
    name: "Report Transaction Inventory",
    component: () => import("../views/report/TransactionInventory.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/inventory/transaction/summary",
    name: "Report Transaction Inventory Summary",
    component: () => import("../views/report/TransactionInventorySummary.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/inventory/transaction/rr",
    name: "Report Transaction RR",
    component: () => import("../views/report/TransactionRR.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/inventory/aging",
    name: "Report Inventory Aging",
    component: () => import("../views/report/InventoryAging.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/inventory/aging/summary",
    name: "Report Inventory Aging Summary",
    component: () => import("../views/report/InventoryAgingSummary.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/inventory/mr",
    name: "Report Inventory Material Request",
    component: () => import("../views/report/InventoryMaterialRequest.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/inventory/ho",
    name: "Report Inventory Hand Over",
    component: () => import("../views/report/InventoryHandOver.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/tanda-terima/kasbon",
    name: "Report Tanda Terima Kasbon",
    component: () => import("../views/report/TandaTerimaKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/expense/kasbon",
    name: "Report Expense Kasbon",
    component: () => import("../views/report/ExpenseKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion

  //#region kasbon router
  {
    path: "/kasbon/general",
    name: "General Kasbon",
    component: () => import("../views/kasbon/general/GeneralKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/general/create",
    name: "Create General Kasbon",
    component: () => import("../views/kasbon/general/CreateGeneralKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/general/:id",
    name: "Detail General Kasbon",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/general/GeneralKasbonView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/general/print/preview/:id",
    name: "Print Preview Kasbon",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/general/PrintPreviewKasbon.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-general",
    name: "Payment General Kasbon",
    component: () => import("../views/kasbon/payment-general/PaymentGeneralKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-general/create",
    name: "Create Payment General Kasbon",
    component: () => import("../views/kasbon/payment-general/CreatePaymentGeneralKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-general/:id",
    name: "Detail Payment General Kasbon",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/payment-general/PaymentGeneralKasbonView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-general/journal/:id",
    name: "Journal Payment General Kasbon",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/payment-general/PrintJournalKasbon.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/query/wo",
    name: "Query Kasbon Wo",
    component: () => import("../views/kasbon/general/QueryKasbonByWo.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement",
    name: "Settlement Kasbon",
    component: () => import("../views/kasbon/settlement/SettlementKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement/create",
    name: "Create Settlement Kasbon",
    component: () => import("../views/kasbon/settlement/CreateSettlementKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement/create/no-kasbon",
    name: "Create Settlement No Kasbon",
    component: () => import("../views/kasbon/settlement/CreateSettlementNoKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement/:id",
    name: "Detail Settlement Kasbon",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/settlement/SettlementKasbonView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement/print/preview/:id",
    name: "Print Preview Settlement",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/settlement/PrintPreviewSettlement.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-settlement",
    name: "Payment Settlement Kasbon",
    component: () => import("../views/kasbon/payment-settlement/PaymentSettlementKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-settlement/create",
    name: "Create Payment Settlement Kasbon",
    component: () => import("../views/kasbon/payment-settlement/CreatePaymentSettlementKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-settlement/:id",
    name: "Detail Payment Settlement Kasbon",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/payment-settlement/PaymentSettlementKasbonView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-settlement/journal/:id",
    name: "Journal Payment Settlement Kasbon",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/payment-settlement/PrintJournalSettlement.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/driver",
    name: "Driver Kasbon",
    component: () => import("../views/kasbon/driver/KasbonDriver.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/driver/create",
    name: "Create Driver Kasbon",
    component: () => import("../views/kasbon/driver/CreateKasbonDriver.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/driver/:id",
    name: "Detail Driver Kasbon",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/driver/KasbonDriverView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-driver",
    name: "Payment Driver Kasbon",
    component: () => import("../views/kasbon/payment-driver/PaymentKasbonDriver.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-driver/create",
    name: "Create Payment Driver Kasbon",
    component: () => import("../views/kasbon/payment-driver/CreatePaymentKasbonDriver.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-driver/:id",
    name: "Detail Payment Driver Kasbon",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/payment-driver/PaymentKasbonDriverView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-driver/journal/:id",
    name: "Journal Payment Driver Kasbon",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/payment-driver/PrintJournalDriver.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement-driver",
    name: "Settlement Driver",
    component: () => import("../views/kasbon/settlement-driver/SettlementDriver.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement-driver/create",
    name: "Create Settlement Driver",
    component: () => import("../views/kasbon/settlement-driver/CreateSettlementDriver.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement-driver/create/no-kasbon",
    name: "Create Settlement Driver No Kasbon",
    component: () => import("../views/kasbon/settlement-driver/CreateSettlementDriverNoKasbon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement-driver/:id",
    name: "Detail Settlement Driver",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/settlement-driver/SettlementDriverView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-settlement-driver",
    name: "Payment Settlement Driver",
    component: () =>
      import("../views/kasbon/payment-settlement-driver/PaymentSettlementDriver.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-settlement-driver/create",
    name: "Create Payment Settlement Driver",
    component: () =>
      import("../views/kasbon/payment-settlement-driver/CreatePaymentSettlementDriver.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-settlement-driver/:id",
    name: "Detail Payment Settlement Driver",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () =>
      import("../views/kasbon/payment-settlement-driver/PaymentSettlementDriverView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/payment-settlement-driver/journal/:id",
    name: "Journal Payment Settlement Driver",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () =>
      import("../views/kasbon/payment-settlement-driver/PrintJournalSettlementDriver.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/kasbon-external",
    name: "Kasbon External",
    component: () => import("../views/kasbon/kasbon-external/KasbonExternal.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/kasbon-external/create",
    name: "Create Kasbon External",
    component: () => import("../views/kasbon/kasbon-external/CreateKasbonExternal.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/kasbon-external/:id",
    name: "Detail Kasbon External",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/kasbon-external/KasbonExternalView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/kasbon-external/journal/:id",
    name: "Journal Kasbon External",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/kasbon-external/PrintJournalKasbonExternal.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement-external",
    name: "Settlement External",
    component: () => import("../views/kasbon/settlement-external/SettlementExternal.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement-external/create",
    name: "Create Settlement External",
    component: () => import("../views/kasbon/settlement-external/CreateSettlementExternal.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement-external/:id",
    name: "Detail Settlement External",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/settlement-external/SettlementExternalView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/settlement-external/journal/:id",
    name: "Journal Settlement External",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () =>
      import("../views/kasbon/settlement-external/PrintJournalSettlementExternal.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/combination/create",
    name: "Create Payment Kasbon Combination",
    component: () => import("../views/kasbon/combination/CreatePaymentKasbonCombination.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/combination",
    name: "Payment Kasbon Combination",
    component: () => import("../views/kasbon/combination/PaymentKasbonCombination.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/combination/detail/:id",
    name: "Detail Payment Kasbon Combination",
    component: () => import("../views/kasbon/combination/DetailPaymentKasbonCombination.vue"),
    props: route => {
      return { id: Number(route.params.id) };
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/combination/merge/journal/:id",
    name: "Merge Journal Payment Kasbon",
    component: () => import("../views/kasbon/combination/MergeJournalKasbonCombination.vue"),
    props: route => {
      return { id: Number(route.params.id) };
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/combination/merge/journal/detail/:id",
    name: "Detail Merge Journal",
    component: () => import("../views/kasbon/combination/DetailMergeJournal.vue"),
    props: route => {
      return { id: Number(route.params.id) };
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/combination/additional/:id",
    name: "Add Additional Payment",
    component: () => import("../views/kasbon/combination/AddAdditionalPayment.vue"),
    props: route => {
      return { id: Number(route.params.id) };
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/combination/journal/:id",
    name: "Print Preview Journal Kasbon Combination",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/kasbon/combination/PrintPreviewKasbonCombination.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/template/create",
    name: "Create Kasbon Template",
    component: () => import("../views/kasbon/template/CreateTemplate.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/template",
    name: "Kasbon Template",
    component: () => import("../views/kasbon/template/Template.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kasbon/template/detail/:id",
    name: "Detail Kasbon Template",
    component: () => import("../views/kasbon/template/TemplateView.vue"),
    props: route => {
      return { id: Number(route.params.id) };
    },
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion

  //#region inventory router
  {
    path: "/inventory",
    name: "Inventory",
    component: () => import("../views/inventory/Inventory.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/rr",
    name: "Journal Inventory",
    component: () => import("../views/inventory/received/JournalInventory.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/mr",
    name: "Journal Material Request",
    component: () => import("../views/inventory/material-request/JournalMaterialRequest.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/ho",
    name: "Journal Hand Over",
    component: () => import("../views/inventory/hand-over/JournalHandOver.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/do",
    name: "Journal Product In Transit",
    component: () => import("../views/inventory/product-in-transit/JournalProductInTransit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/return-mr",
    name: "Journal Return MR",
    component: () => import("../views/inventory/return-mr/JournalReturnMR.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/rfs",
    name: "Journal Rfs",
    component: () => import("../views/inventory/rfs/JournalRfs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/journal/rr/:id",
    name: "Detail Journal Inventory",
    props: route => {
      return {
        id: Number(route.params.id),
        type: route.query.type,
        relationId: Number(route.query.relationId),
      };
    },
    component: () => import("../views/inventory/received/JournalInventoryView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/journal/mr/:id",
    name: "Detail Journal Material Request",
    props: route => {
      return {
        id: Number(route.params.id),
        type: route.query.type,
        relationId: Number(route.query.relationId),
      };
    },
    component: () => import("../views/inventory/material-request/JournalMaterialRequestView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/journal/ho/:id",
    name: "Detail Journal Hand Over",
    props: route => {
      return {
        id: Number(route.params.id),
        type: route.query.type,
        relationId: Number(route.query.relationId),
      };
    },
    component: () => import("../views/inventory/hand-over/JournalHandOverView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/journal/do/:id",
    name: "Detail Journal Product In Transit",
    props: route => {
      return {
        id: Number(route.params.id),
        type: route.query.type,
        relationId: Number(route.query.relationId),
      };
    },
    component: () =>
      import("../views/inventory/product-in-transit/JournalProductInTransitView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/journal/rmr/:id",
    name: "Detail Journal Return MR",
    props: route => {
      return {
        id: Number(route.params.id),
        type: route.query.type,
        relationId: Number(route.query.relationId),
      };
    },
    component: () => import("../views/inventory/return-mr/JournalReturnMRView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/hpp",
    name: "HPP",
    component: () => import("../views/inventory/hpp/Hpp.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-inventory/journal/rfs/:id",
    name: "Detail Journal RFS",
    props: route => {
      return {
        id: Number(route.params.id),
        type: route.query.type,
        relationId: Number(route.query.relationId),
      };
    },
    component: () => import("../views/inventory/rfs/JournalRfsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion

  //#region wo router
  {
    path: "/wo",
    name: "Wo",
    component: () => import("../views/wo/Wo.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () => import("../views/wo/Tasks.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/wo/create",
    name: "Create Wo",
    component: () => import("../views/wo/CreateWo.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/wo/:id",
    name: "Detail Wo",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/wo/WoView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/task-template",
    name: "Task Template",
    component: () => import("../views/task-template/TaskTemplate.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/task-template/create",
    name: "Create Task Template",
    component: () => import("../views/task-template/CreateTaskTemplate.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/task-template/:id",
    name: "Detail Task Template",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/task-template/TaskTemplateView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion

  //#region pph router
  {
    path: "/pph/23",
    name: "PPH 23",
    component: () => import("../views/pph/23/TaxPph23.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/23/create",
    name: "Create PPH 23",
    component: () => import("../views/pph/23/CreateTaxPph23.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/23/detail/:id",
    name: "Detail PPH 23",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/pph/23/TaxPph23View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/23/code",
    name: "PPH 23 Code",
    component: () => import("../views/pph/23-code/TaxPph23Code.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/23/code/create",
    name: "Create PPH 23 Code",
    component: () => import("../views/pph/23-code/CreateTaxPph23Code.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/23/code/detail/:id",
    name: "Detail PPH 23 Code",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/pph/23-code/TaxPph23CodeView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/4/verse/2",
    name: "PPH 4 Verse 2",
    component: () => import("../views/pph/4-verse-2/TaxPph4Verse2.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/4/verse/2/create",
    name: "Create PPH 4 Verse 2",
    component: () => import("../views/pph/4-verse-2/CreateTaxPph4Verse2.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/4/verse/2/detail/:id",
    name: "Detail PPH 4 Verse 2",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/pph/4-verse-2/TaxPph4Verse2View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/22",
    name: "PPH 22",
    component: () => import("../views/pph/22/TaxPph22.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/22/create",
    name: "Create PPH 22",
    component: () => import("../views/pph/22/CreateTaxPph22.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/22/detail/:id",
    name: "Detail PPH 22",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/pph/22/TaxPph22View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/22-import",
    name: "PPH 22 Import",
    component: () => import("../views/pph/22-import/TaxPph22Import.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/22-import/create",
    name: "Create PPH 22 Import",
    component: () => import("../views/pph/22-import/CreateTaxPph22Import.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/22-import/detail/:id",
    name: "Detail PPH 22 Import",
    props: route => {
      return { id: Number(route.params.id) };
    },
    component: () => import("../views/pph/22-import/TaxPph22ImportView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pph/query",
    name: "PPH Query",
    component: () => import("../views/pph/QueryPph.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion
  //#region closing router
  {
    path: "/fiscal-month",
    name: "Fiscal Month",
    component: () => import("../views/closing/fiscal/FiscalMonth.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/check-balances",
    name: "Check Balance",
    component: () => import("../views/closing/check-balance/CheckBalance.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/check-balance/create",
    name: "Create Check Balance",
    component: () => import("../views/closing/check-balance/CreateCheckBalance.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/check-balance/template",
    name: "Template",
    component: () => import("../views/closing/check-balance/Template.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const authenticatedUser = localStorage.getItem("access_token");
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth);

  if (requiresAuth && !authenticatedUser) next("login");
  else next();
});

export default router;
