import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Admin from "./layouts/Admin.vue";
import Auth from "./layouts/Auth.vue";
import vuetify from "./plugins/vuetify";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

Vue.use(VueToast);

Vue.config.productionTip = false;

Vue.component("auth", Auth);
Vue.component("admin", Admin);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
