import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/tax";

//#region state
const state = {
  taxNumbers: [],
  serialNumber: "",
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        route.push({ name: "Tax Number" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async skip(context, params) {
    try {
      const response = await api.post(`${BASE_URL}/skip`, {}, { params: params });
      if (response.status === 200) {
        route.push({ name: "Tax Number" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async change(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/change`, body);
      if (response.status === 200) {
        route.push({ name: "Tax Number" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context, used) {
    try {
      const response = await api.get(BASE_URL, { used: used });
      if (response.status === 200) {
        context.commit("SET_TAX_NUMBERS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getInvoiceTaxes() {
    try {
      const response = await api.get(`${BASE_URL}/invoices`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/number`);
      if (response.status === 200) {
        context.commit("SET_SERIAL_NUMBER", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_SERIAL_NUMBER(state, payload) {
    state.serialNumber = payload;
  },
  SET_TAX_NUMBERS(state, payload) {
    state.taxNumbers = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
