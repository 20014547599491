import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/giro";

//#region state
const state = {
  listGiro: [],
  giros: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        route.push({ name: "Giro" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context) {
    try {
      const response = await api.get(BASE_URL);
      if (response.status === 200) {
        context.commit("SET_GIROS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListGiro() {
    try {
      const response = await api.get(`${BASE_URL}/dropdown`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPreviewJournals(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/journal/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async saveJournals(context, params) {
    try {
      const response = await api.post(`${BASE_URL}/journal/${params.id}`, params.items, {
        params: { date: params.date },
      });
      if (response.status === 200) {
        route.push({ name: "Giro" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_GIROS(state, payload) {
    state.giros = payload;
  },
  SET_LIST_GIRO(state, payload) {
    state.listGiro = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
