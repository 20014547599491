//#region menu user
const user = [
  {
    icon: "mdi-view-dashboard",
    title: "dashboard",
    to: "/dashboard",
  },
  {
    icon: "mdi-book-multiple",
    title: "General Transaction",
    children: [
      {
        title: "Chart Of Accounts",
        icon: "mdi-clipboard-outline",
        to: "/general-ledger/chart-of-accounts",
      },
      // {
      //   title: "Employee Payroll",
      //   icon: "mdi-clipboard-outline",
      //   to: "/general-ledger/employee-payroll",
      // },
      {
        title: "Transaction",
        icon: "mdi-file-document-multiple",
        to: "/general-ledger/transaction",
      },
      // {
      //   title: "Budget",
      //   icon: "mdi-clipboard-outline",
      //   to: "/general-ledger/budget",
      // },
      // {
      //   title: "Journal",
      //   icon: "mdi-clipboard-outline",
      //   to: "/general-ledger/journal",
      // },
      {
        title: "Query Journal",
        icon: "mdi-clipboard-outline",
        to: "/general-ledger/query/journal",
      },
      {
        title: "Approval Journal",
        icon: "mdi-clipboard-outline",
        to: "/general-ledger/approval/journal",
      },
    ],
  },
  {
    title: "Bank",
    icon: "mdi-wallet",
    children: [
      // {
      //   title: "Other Payment",
      //   icon: "mdi-clipboard-outline",
      //   to: "/cash-and-bank/other-payment",
      // },
      // {
      //   title: "Other Deposit",
      //   icon: "mdi-clipboard-outline",
      //   to: "/cash-and-bank/other-deposit",
      // },
      // {
      //   title: "Bank Transfer",
      //   icon: "mdi-clipboard-outline",
      //   to: "/cash-and-bank/bank-transfer",
      // },
      {
        title: "Bank",
        icon: "mdi-clipboard-outline",
        to: "/bank/general",
      },
      {
        title: "Bank Statement",
        icon: "mdi-clipboard-outline",
        to: "/bank/bank-statement",
      },
      {
        title: "Bank Guarantee",
        icon: "mdi-clipboard-outline",
        to: "/bank/bank-guarantee",
      },
      // {
      //   title: "Bank Reconcile",
      //   icon: "mdi-clipboard-outline",
      //   to: "/cash-and-bank/bank-reconcile",
      // },
    ],
  },
  {
    title: "Sales",
    icon: "mdi-cart",
    children: [
      {
        title: "Sales Down Payment",
        icon: "mdi-cart-arrow-down",
        to: "/sales/sale-down-payment",
      },
      {
        title: "Sales Invoice DO",
        icon: "mdi-cart-plus",
        to: "/sales/sale-invoice",
      },
      {
        title: "Sales Invoice PO",
        icon: "mdi-cart-plus",
        to: "/sales/sale-invoice-po",
      },
      // {
      //   title: "Sales Invoice BDO",
      //   icon: "mdi-cart-plus",
      //   to: "/sales/sale-invoice-bdo",
      // },
      {
        title: "Sales Proforma",
        icon: "mdi-cart-off",
        to: "/sales/sale-proforma",
      },
      {
        title: "Sales Payment",
        icon: "mdi-credit-card",
        to: "/sales/sale-payment",
      },
      {
        title: "Sales Payment Approval",
        icon: "mdi-credit-card",
        to: "/sales/payment/approval",
      },
      {
        title: "Sales Return",
        icon: "mdi-cart-minus",
        to: "/sales/sale-return",
      },
      {
        title: "Update Remark Follow Up",
        icon: "mdi-cart-minus",
        to: "/sales/update/remark",
      },
      // {
      //   title: "Billing DO",
      //   icon: "mdi-cart-minus",
      //   to: "/sales/billing-do",
      // },
      {
        title: "Template Bank",
        icon: "mdi-cart-minus",
        to: "/sales/template/bank",
      },
      {
        title: "Print Invoice",
        icon: "mdi-printer",
        to: "/sales/print/invoice",
      },
      {
        title: "Query Invoice",
        icon: "mdi-printer",
        to: "/sales/invoice/query",
      },
      {
        title: "Query Payment",
        icon: "mdi-printer",
        to: "/sales/payment/query",
      },
      {
        title: "Cancel Invoice",
        icon: "mdi-printer",
        to: "/sales/cancel/invoice",
      },
      {
        title: "Convert DP to Sell",
        icon: "mdi-printer",
        to: "/sales/sale-down-payment/convert/sell",
      },
      {
        title: "Query Link DO",
        icon: "mdi-printer",
        to: "/sales/link-do/query",
      },
      {
        title: "Query DO",
        icon: "mdi-printer",
        to: "/sales/do/query",
      },
    ],
  },
  {
    title: "Purchases",
    icon: "mdi-basket",
    children: [
      {
        title: "Purchase Down Payment",
        icon: "mdi-basket-fill",
        to: "/purchases/purchase-down-payment",
      },
      {
        title: "Purchase Invoice",
        icon: "mdi-basket-plus",
        to: "/purchases/purchase-invoice",
      },
      {
        title: "Replecement Cost",
        icon: "mdi-basket-minus",
        to: "/purchases/replacement-cost",
      },
      {
        title: "Forwarder Invoice",
        icon: "mdi-basket-minus",
        to: "/purchases/forwarder-invoice",
      },
      {
        title: "Forwarder Payment",
        icon: "mdi-basket-remove",
        to: "/purchases/forwarder-payment",
      },
      {
        title: "Purchase Payment",
        icon: "mdi-credit-card",
        to: "/purchases/purchase-payment",
      },
      {
        title: "Purchase Payment Approval",
        icon: "mdi-credit-card",
        to: "/purchases/payment/approval",
      },
      {
        title: "Purchase Return",
        icon: "mdi-basket-minus",
        to: "/purchases/purchase-return",
      },
    ],
  },
  {
    title: "Kasbon",
    icon: "mdi-account-cash",
    children: [
      {
        title: "Kasbon",
        icon: "mdi-cash-multiple",
        to: "/kasbon/general",
      },
      {
        title: "Payment Kasbon",
        icon: "mdi-cash-check",
        to: "/kasbon/payment-general",
      },
      {
        title: "Settlement Kasbon",
        icon: "mdi-cash-lock",
        to: "/kasbon/settlement",
      },
      {
        title: "Payment Settlement Kasbon",
        icon: "mdi-cash-lock-open",
        to: "/kasbon/payment-settlement",
      },
      {
        title: "Template Kasbon",
        icon: "mdi-cash-lock-open",
        to: "/kasbon/template",
      },
      {
        title: "Query By WO",
        icon: "mdi-cash-lock-open",
        to: "/kasbon/query/wo",
      },
    ],
  },
  // {
  //   title: "Kasbon Driver",
  //   icon: "mdi-account-cash",
  //   children: [
  //     {
  //       title: "Driver Kasbon",
  //       icon: "mdi-cash-multiple",
  //       to: "/kasbon/driver",
  //     },
  //     {
  //       title: "Payment Driver Kasbon",
  //       icon: "mdi-cash-check",
  //       to: "/kasbon/payment-driver",
  //     },
  //     {
  //       title: "Settlement Driver Kasbon",
  //       icon: "mdi-cash-lock",
  //       to: "/kasbon/settlement-driver",
  //     },
  //     {
  //       title: "Payment Settlement Driver Kasbon",
  //       icon: "mdi-cash-lock-open",
  //       to: "/kasbon/payment-settlement-driver",
  //     },
  //   ],
  // },
  // {
  //   title: "Kasbon Installer",
  //   icon: "mdi-account-cash",
  //   children: [
  //     {
  //       title: "Payment Kasbon Installer",
  //       icon: "mdi-cash-multiple",
  //       to: "/kasbon/kasbon-external",
  //     },
  //     {
  //       title: "Payment Settlement Installer",
  //       icon: "mdi-cash-multiple",
  //       to: "/kasbon/settlement-external",
  //     },
  //   ],
  // },
  {
    title: "Kasbon Join",
    icon: "mdi-account-cash",
    to: "/kasbon/combination",
  },
  // {
  //   title: "Assets",
  //   icon: "mdi-map-marker",
  //   children: [
  //     {
  //       title: "Fixed Asset",
  //       icon: "mdi-clipboard-outline",
  //       to: "/asset/fixed-asset",
  //     },
  //     {
  //       title: "Fixed Asset Category",
  //       icon: "mdi-clipboard-outline",
  //       to: "/asset/fixed-asset-category",
  //     },
  //     {
  //       title: "Fiscal Fixed Asset Category",
  //       icon: "mdi-clipboard-outline",
  //       to: "/asset/fiscal-fixed-asset-category",
  //     },
  //     {
  //       title: "Fixet Asset Edited",
  //       icon: "mdi-clipboard-outline",
  //       to: "/asset/fixed-asset-edited",
  //     },
  //     {
  //       title: "Fixed Asset Dispotition",
  //       icon: "mdi-clipboard-outline",
  //       to: "/asset/fixed-asset-dispotition",
  //     },
  //     {
  //       title: "Asset Transfer",
  //       icon: "mdi-clipboard-outline",
  //       to: "/asset/transfer",
  //     },
  //   ],
  // },
  {
    title: "Inventory",
    icon: "mdi-account-cash",
    children: [
      {
        icon: "mdi-view-dashboard",
        title: "Inventory",
        to: "/inventory",
      },
      {
        icon: "mdi-view-dashboard",
        title: "RR",
        to: "/transaction-inventory/rr",
      },
      {
        icon: "mdi-view-dashboard",
        title: "MR",
        to: "/transaction-inventory/mr",
      },
      {
        icon: "mdi-view-dashboard",
        title: "HO",
        to: "/transaction-inventory/ho",
      },
      {
        icon: "mdi-view-dashboard",
        title: "In Transit",
        to: "/transaction-inventory/do",
      },
      {
        icon: "mdi-view-dashboard",
        title: "Return MR",
        to: "/transaction-inventory/return-mr",
      },
      {
        icon: "mdi-view-dashboard",
        title: "RFS",
        to: "/transaction-inventory/rfs",
      },
      {
        icon: "mdi-view-dashboard",
        title: "HPP",
        to: "/hpp",
      },
    ],
  },
  {
    title: "WO",
    icon: "mdi-account-cash",
    children: [
      {
        title: "WO",
        icon: "mdi-cash-multiple",
        to: "/wo",
      },
      {
        title: "Tasks",
        icon: "mdi-cash-multiple",
        to: "/tasks",
      },
      {
        title: "Task Template",
        icon: "mdi-cash-multiple",
        to: "/task-template",
      },
    ],
  },
  {
    title: "Closing",
    icon: "mdi-account-cash",
    children: [
      {
        title: "Fiscal Month",
        icon: "mdi-cash-multiple",
        to: "/fiscal-month",
      },
      {
        title: "Check Balance",
        icon: "mdi-cash-multiple",
        to: "/check-balances",
      },
      {
        title: "Check balance Template",
        icon: "mdi-cash-multiple",
        to: "/check-balance/template",
      },
    ],
  },
  {
    icon: "mdi-view-dashboard",
    title: "Job Dept Template Overview",
    to: "/job/dept/template/overview",
  },
  {
    icon: "mdi-view-dashboard",
    title: "Project Document Integration",
    to: "/project/document/integration",
  },
  {
    title: "Reports",
    icon: "mdi-archive",
    to: "/report",
  },
  {
    title: "PPH",
    icon: "mdi-account-cash",
    children: [
      {
        title: "23",
        icon: "mdi-cash-multiple",
        to: "/pph/23",
      },
      {
        title: "22",
        icon: "mdi-cash-check",
        to: "/pph/22",
      },
      {
        title: "22 Import",
        icon: "mdi-cash-lock",
        to: "/pph/22-import",
      },
      {
        title: "4 Verse 2",
        icon: "mdi-cash-lock-open",
        to: "/pph/4/verse/2",
      },
      {
        title: "Query",
        icon: "mdi-cash-lock-open",
        to: "/pph/query",
      },
    ],
  },
];
//#endregion

//#region menu admin
let admin = [
  ...user,
  {
    title: "Assets",
    icon: "mdi-archive",
    children: [
      {
        title: "Fixed Asset",
        icon: "mdi-archive-arrow-up",
        to: "/asset/fixed-asset",
      },
      {
        title: "Fixed Asset Category",
        icon: "mdi-archive-alert",
        to: "/asset/fixed-asset-category",
      },
      // {
      //   title: "Fixed Asset Internal",
      //   icon: "mdi-archive-arrow-up",
      //   to: "/asset/fixed-asset-internal",
      // },
    ],
  },
  {
    title: "Setting",
    icon: "mdi-cog",
    children: [
      {
        title: "Users",
        icon: "mdi-account",
        to: "/setting/users",
      },
      {
        title: "Currency",
        icon: "mdi-currency-usd",
        to: "/setting/currency",
      },
      {
        title: "Account Number",
        icon: "mdi-wallet",
        to: "/setting/account-number",
      },
      {
        title: "Requirement Category",
        icon: "mdi-rhombus-split",
        to: "/setting/requirement-category",
      },
      {
        title: "Tax Number",
        icon: "mdi-rhombus-split",
        to: "/setting/tax-number",
      },
      {
        title: "NPWP",
        icon: "mdi-rhombus-split",
        to: "/setting/npwp",
      },
      {
        title: "Giro",
        icon: "mdi-rhombus-split",
        to: "/setting/giro",
      },
      {
        title: "Check",
        icon: "mdi-rhombus-split",
        to: "/setting/check",
      },
      {
        title: "Notification",
        icon: "mdi-rhombus-split",
        to: "/setting/notification",
      },
      {
        title: "Tax",
        icon: "mdi-rhombus-split",
        to: "/setting/tax",
      },
      {
        title: "Tax 23 Code",
        icon: "mdi-rhombus-split",
        to: "/pph/23/code",
      },
      {
        title: "Income Statement",
        icon: "mdi-rhombus-split",
        to: "/setting/income-statement",
      },
      {
        title: "Vendor",
        icon: "mdi-rhombus-split",
        to: "/setting/vendor",
      },
    ],
  },
];
//#endregion

//#region menu manager
let manager = [...user];
//#endregion

export default {
  admin,
  manager,
  user,
};
