import api from "@/services/api";
import { Promise } from "core-js";
import jwt_decode from "jwt-decode";
import route from "@/router/index";

const URL_LOGIN = "/auth/login";

//#region state
const state = {
  token: null,
  notifications: [],
  tax: [],
  userDetail: {},
  checkRole: false,
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async login(context, body) {
    try {
      const response = await api.post(URL_LOGIN, body);
      if (response.status === 200) {
        localStorage.setItem("access_token", response.data.accessToken);
        localStorage.setItem("setting_notification", JSON.stringify(response.data.notifications));
        localStorage.setItem("setting_tax", JSON.stringify(response.data.tax));
        context.commit("SET_TOKEN", response.data.accessToken);
        context.commit("SET_NOTIFICATIONS", response.data.notifications);
        context.commit("SET_TAX", response.data.tax);
        context.dispatch("decodeJwt", response.data.accessToken);
        route.push({ name: "Dashboard" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  logout(context) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("setting_notification");
    localStorage.removeItem("setting_tax");
    context.commit("SET_TOKEN", null);
    context.commit("SET_NOTIFICATIONS", []);
    context.commit("SET_TAX", []);
    route.push({ name: "Login" });
  },
  decodeJwt(context, token) {
    const user = jwt_decode(token);
    context.commit("SET_USER_DETAIL", user);
    context.commit("SET_CHECK_ROLE", user.role === "ROLE_MANAGER");
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload;
  },
  SET_TAX(state, payload) {
    state.tax = payload;
  },
  SET_USER_DETAIL(state, payload) {
    state.userDetail = payload;
  },
  SET_CHECK_ROLE(state, payload) {
    state.checkRole = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
