import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/income-statement";

//#region state
const state = {
  listType: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        route.push({ name: "Income Statement" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll() {
    try {
      const response = await api.get(BASE_URL);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getType(context) {
    try {
      const response = await api.get(`${BASE_URL}/types`);
      if (response.status === 200) {
        context.commit("SET_TYPES", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPreview(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/preview`, params);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getWcPreview(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/wc/preview`, params);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async saveBulk(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/bulk`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAmount(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/amount`, body);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_TYPES(state, payload) {
    state.listType = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
