import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/currency";

//#region state
const state = {
  currencies: [],
  currency: {},
  dropDownCurrency: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        route.push({ name: "Detail Currency", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateNominal(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/nominal`, body);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Currency" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll() {
    try {
      const response = await api.get(BASE_URL);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getByCode(context, code) {
    try {
      const response = await api.get(`${BASE_URL}/code/${code}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getRate(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/rate`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDropDownCurrency(context) {
    try {
      const response = await api.get(`${BASE_URL}/dropdown`);
      context.commit("SET_DROPDOWN_CURRENCY", response.data);
    } catch (error) {
      return Promise.resolve(error);
    }
  },
  async getListCurrency(context) {
    try {
      const response = await api.get(`${BASE_URL}/dropdown/code`);
      context.commit("SET_DROPDOWN_CURRENCY", response.data);
      // return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_CURRENCIES(state, payload) {
    state.currencies = payload;
  },
  SET_CURRENCY(state, payload) {
    state.currency = payload;
  },
  SET_DROPDOWN_CURRENCY(state, payload) {
    state.dropDownCurrency = payload;
  },
  ADD_ALL(state, payload) {
    state.dropDownCurrency.unshift(payload);
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
