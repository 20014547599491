import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/template/bank";

//#region state
const state = {
  templateBanks: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, data) {
    try {
      const response = await api.post(BASE_URL, data.form);
      if (response.status === 200) {
        if (data.type === "create") {
          route.push({ name: "Detail Template Bank" });
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll() {
    try {
      const response = await api.get(BASE_URL);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTemplateBank(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setDefault(context, id) {
    try {
      const response = await api.post(`${BASE_URL}/default/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
