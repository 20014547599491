<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <v-card>
      <v-card-title>
        <span class="headline text--red">{{ error.title }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div>{{ error.text }}</div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="text-center">
        <v-btn small color="primary" @click="close">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-error",
  props: {
    error: Object,
    dialog: Boolean,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
