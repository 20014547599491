import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/purchase/replacement-costs";

//#region state
const state = {
  replacementCosts: [],
  lastFormNumber: null,
  sequence: null,
  dropdowns: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        route.push({ name: "Detail Replacement Cost", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getLastFormNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/form-number`);
      if (response.status === 200) {
        let num = "";
        let date = new Date();
        if (response.data === "New") {
          num = `RC.${date.getFullYear()}.${date.getMonth() + 1}.00001`;
        } else {
          const lastNumber = response.data ? response.data.split(".")[3] : "";
          const ctx = "" + (parseInt(lastNumber) + 1);
          const end = lastNumber.substr(0, lastNumber.length - ctx.length) + ctx;
          num = `RC.${date.getFullYear()}.${date.getMonth() + 1}.${end}`;
        }
        context.commit("SET_LAST_FORM_NUMBER", num);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSequence(context) {
    try {
      const response = await api.get(`${BASE_URL}/sequence`);
      if (response.status === 200) {
        context.commit("SET_LAST_REPLACEMENT_COST_NUMBER", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context) {
    try {
      const response = await api.get(`${BASE_URL}`);
      if (response.status === 200) {
        context.commit("SET_REPLACEMENT_COSTS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDropDown(context) {
    try {
      const response = await api.get(`${BASE_URL}/drop-down`);
      if (response.status === 200) {
        context.commit("SET_DROPDOWN", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Replacement Cost" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LAST_FORM_NUMBER(state, payload) {
    state.lastFormNumber = payload;
  },
  SET_LAST_REPLACEMENT_COST_NUMBER(state, payload) {
    state.sequence = payload;
  },
  SET_REPLACEMENT_COSTS(state, payload) {
    state.replacementCosts = payload;
  },
  SET_DROPDOWN(state, payload) {
    state.dropdowns = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
