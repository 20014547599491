import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/purchase/returns";

//#region state
const state = {
  purchaseReturns: [],
  lastFormNumber: null,
  purchaseReturn: {},
  listInvoice: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status == 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        route.push({ name: "Detail Purchase Return", params: { id: response.data } });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getLastFormNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/form-number`);
      if (response.status === 200) {
        let num = null;
        let date = new Date();
        if (response.data === "New") {
          num = `PR.${date.getFullYear()}.${date.getMonth() + 1}.00001`;
        } else {
          const lastNumber = response.data ? response.data.split(".")[3] : "";
          const ctx = "" + (parseInt(lastNumber) + 1);
          const end = lastNumber.substr(0, lastNumber.length - ctx.length) + ctx;
          num = `PR.${date.getFullYear()}.${date.getMonth() + 1}.${end}`;
        }
        context.commit("SET_LAST_FORM_NUMBER", num);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context) {
    try {
      const response = await api.get(`${BASE_URL}`);
      if (response.status === 200) {
        context.commit("SET_PURCHASE_RETURNS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, purchaseReturnId) {
    try {
      const response = await api.get(`${BASE_URL}/${purchaseReturnId}`);
      // if (response.status === 200) {
      //   context.commit("SET_PURCHASE_RETURN", response.data);
      // }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Purchase Return" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListInvoice(context, vendorId) {
    try {
      const response = await api.get(`${BASE_URL}/invoice/${vendorId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListInvoiceById(context, purchaseReturnId) {
    try {
      const response = await api.get(`${BASE_URL}/invoice/by/${purchaseReturnId}`);
      // context.commit("SET_LIST_INVOICE", response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LAST_FORM_NUMBER(state, payload) {
    state.lastFormNumber = payload;
  },
  SET_PURCHASE_RETURN(state, payload) {
    state.purchaseReturn = payload;
  },
  SET_PURCHASE_RETURNS(state, payload) {
    state.purchaseReturns = payload;
  },
  SET_LIST_INVOICE(state, payload) {
    state.listInvoice = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
