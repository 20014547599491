<template>
  <v-app>
    <component :is="layout">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <loader></loader>
      <v-snackbar timeout="-1" v-model="isRefresh">
        There new content. please click refresh button

        <template v-slot:action="{ attrs }">
          <v-btn color="success" v-bind="attrs" @click="update">
            Refresh
          </v-btn>
        </template>
      </v-snackbar>
    </component>
  </v-app>
</template>

<script>
const default_layout = "admin";
import Loader from "@/components/Loader.vue";

export default {
  name: "App",
  components: {
    Loader,
  },

  data: () => ({
    registration: null,
    isRefresh: false,
    refreshing: false,
  }),

  computed: {
    layout() {
      return this.$route.meta.layout || default_layout;
    },
  },

  methods: {
    userDetail() {
      if (localStorage.getItem("access_token")) {
        this.$store.dispatch("auth/decodeJwt", localStorage.getItem("access_token"));
      }
    },
    appUpdateUI: function(e) {
      this.registration = e.detail;
      this.isRefresh = true;
    },
    update() {
      this.isRefresh = false;
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
  },

  mounted() {
    this.userDetail();
  },
  created() {
    document.addEventListener("serviceWorkerUpdateEvent", this.appUpdateUI, { once: true });
  },
};
</script>
