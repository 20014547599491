import api from "@/services/api";
import { Promise } from "core-js";

const BASE_URL = "/reports";

//#region state
const state = {
  reports: [],
  apDetail: {},
  arDetail: {},
  otherApDetail: {},
  neraca: {},
  labaRugi: {},
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async getAllJournal(context, params) {
    try {
      const response = await api.get(BASE_URL, params);
      context.commit("SET_REPORTS", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async generalLedger(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/general-ledger`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async trialBalance(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/trial-balance`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getExcelTrialBalance(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/trial-balance/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async trialBalanceV2(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/trial-balance/v2`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getExcelTrialBalanceV2(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/trial-balance/v2/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async labaRugi(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/laba-rugi/1`, params);
      context.commit("SET_LABA_RUGI", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async labaRugiExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/laba-rugi/excel/1`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async labaRugiV2(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/laba-rugi/v2/1`, params);
      context.commit("SET_LABA_RUGI", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async labaRugiExcelV2(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/laba-rugi/v2/excel/1`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async cogs(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/cogs/1`, params);
      context.commit("SET_LABA_RUGI", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async cogsExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/cogs/excel/3`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async cogm(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/cogm/1`, params);
      context.commit("SET_LABA_RUGI", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async cogmExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/cogm/excel/4`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async neraca(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/neraca/2`, params);
      context.commit("SET_NERACA", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async neracaExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/neraca/excel/2`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async neracaV2(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/neraca/v2/2`, params);
      context.commit("SET_NERACA", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async neracaExcelV2(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/neraca/v2/excel/2`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async currency(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/currency`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async currencyExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/currency/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async saleReturn(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/sale/return`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async saleReturnExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/sale/return/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async cashBudget(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/cash-budget`, body);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async cashBudgetV2(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/cash-budget/v2`, body);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async cashBudgetItem() {
    try {
      const response = await api.get(`${BASE_URL}/cash-budget/v2/items`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async cashBudgetExcel(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/cash-budget/excel`, body, {
        responseType: "blob",
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async cashBudgetExcelV2(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/cash-budget/v2/excel`, body, {
        responseType: "blob",
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getRevaluation(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/revaluation`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createJournalRevaluation(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/revaluation`, body);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async reval(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/reval`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async revalExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/reval/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async apDetail(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/account-payable`, params);
      context.commit("SET_AP", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async arDetail(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/account-receivable`, params);
      context.commit("SET_AR", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async apExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/account-payable/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async arExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/account-receivable/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async arSummary(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/account-receivable/summary`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async arSummaryExcel(context, params) {
    try {
      const response = await api.get(
        `${BASE_URL}/account-receivable/summary/excel`,
        params,
        "blob"
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async payment(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/payment/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async sales(context, year) {
    try {
      const response = await api.get(`${BASE_URL}/sales/excel?year=${year}`, {}, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async otherApDetail(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/other-account-payable`, params);
      context.commit("SET_OTHER_AP", response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async otherApDetailExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/other-account-payable/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async productDetail(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/product-detail`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async bankStatementExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/bank-statement/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async bankGuaranteeExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/bank-guarantee/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async kasbonInstallerExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/kasbon-installer/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async kasbonExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/kasbons/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async kasbon(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/kasbons`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async settlementKasbonExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/settlement/kasbon/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async pettyCashExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/petty-cash/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async paymentSaleDownPaymentExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/payment/sale-down-payment/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async generalLedgerExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/general-ledger/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async depreciationExcel() {
    try {
      const response = await api.get(`${BASE_URL}/depreciation/excel`, {}, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async journals(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/journals`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getExcelJournals(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/journals/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async exportPajak(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/export/pajak/${params.id}`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async exportPajakAll(context, params) {
    try {
      const response = await api.post(`${BASE_URL}/export/pajak`, params, { responseType: "blob" });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async searchInvoice(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/search/invoice`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDropdownPajak(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/dropdown/invoice`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async ppn(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/ppn`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async ppnExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/ppn/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async pph(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/pph/23`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async pphExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/pph/23/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async beginBalance(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/chart-of-account/begin-balance`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async beginBalanceApproved(context, year) {
    try {
      const response = await api.post(`${BASE_URL}/chart-of-account/begin-balance/confirm/${year}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async bufferBeginBalanceApproved(context, body) {
    try {
      const response = await api.post(
        `${BASE_URL}/chart-of-account/begin-balance/confirm/buffer/${body.year}`,
        body.data
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async beginBalanceExcel(context, params) {
    try {
      const response = await api.get(
        `${BASE_URL}/chart-of-account/begin-balance/excel`,
        params,
        "blob"
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async importPpn(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/ppn/import`, body);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async saveReportPpnKeluaran(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/ppn/sale`, body);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async incomeStatementMonthly(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/income-statement/monthly`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async revenueYearly(context, year) {
    try {
      const response = await api.get(`${BASE_URL}/income-statement/revenue/yearly`, { year: year });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async workingCapital(context, year) {
    try {
      const response = await api.get(`${BASE_URL}/working-capital/${year}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async workingCapitalExcel(context, year) {
    try {
      const response = await api.get(`${BASE_URL}/working-capital/${year}/excel`, {}, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async apTax(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/account-payable/tax`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async apTaxExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/account-payable/tax/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSummarySettlementKasbon(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/summary/settlement-kasbon`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSummaryKasbon(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/summary/kasbon`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getBomDetail(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/bom/detail/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async transactionInventory(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/inventory/transaction`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async transactionInventoryExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/inventory/transaction/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async transactionInventorySummary(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/inventory/transaction/summary`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async transactionInventorySummaryExcel(context, params) {
    try {
      const response = await api.get(
        `${BASE_URL}/inventory/transaction/summary/excel`,
        params,
        "blob"
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async transactionRR(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/inventory/transaction/rr`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async transactionRRExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/inventory/transaction/rr/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async inventoryAging() {
    try {
      const response = await api.get(`${BASE_URL}/inventory/aging`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async inventoryAgingExcel() {
    try {
      const response = await api.get(`${BASE_URL}/inventory/aging/excel`, {}, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async inventoryAgingSummary() {
    try {
      const response = await api.get(`${BASE_URL}/inventory/aging/summary`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async inventoryAgingSummaryExcel() {
    try {
      const response = await api.get(`${BASE_URL}/inventory/aging/summary/excel`, {}, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async transactionMR(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/inventory/mr`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async transactionMRExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/inventory/mr/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async transactionHO(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/inventory/ho`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async transactionHOExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/inventory/ho/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async tandaTerimaKasbon(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/tanda-terima/kasbon`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async tandaTerimaKasbonExcel(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/tanda-terima/kasbon/excel`, body, {
        responseType: "blob",
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async cashFlow(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/cash-flow`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async cashFlowExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/cash-flow/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async expenseKasbon(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/expense/kasbon`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async expenseKasbonExcel(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/expense/kasbon/excel`, params, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_REPORTS(state, payload) {
    state.reports = payload;
  },
  SET_AP(state, payload) {
    state.apDetail = payload;
  },
  SET_AR(state, payload) {
    state.arDetail = payload;
  },
  SET_OTHER_AP(state, payload) {
    state.otherApDetail = payload;
  },
  SET_NERACA(state, payload) {
    state.neraca = payload;
  },
  SET_LABA_RUGI(state, payload) {
    state.labaRugi = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
