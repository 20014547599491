import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/sale/payments";

//#region state
const state = {
  salePayments: [],
  lastFormNumber: null,
  voucherNumber: null,
  salePayment: {},
  listInvoice: [],
  listInvoiceDp: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async create(context, data) {
    try {
      const response = await api.post(BASE_URL, data.body);
      if (response.status === 200) {
        context.dispatch(
          "toast",
          { type: "success", message: "Success Saved Data" },
          { root: true }
        );
        if (data.type === "create") {
          route.push({ name: "Detail Sale Payment", params: { id: response.data } });
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async approved(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/approval/update`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getLastFormNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/form-number`);
      if (response.status === 200) {
        let num = null;
        let date = new Date();
        if (response.data === "New") {
          num = `SP.${date.getFullYear()}.${date.getMonth() + 1}.00001`;
        } else {
          const lastNumber = response.data ? response.data.split(".")[3] : "";
          const ctx = "" + (parseInt(lastNumber) + 1);
          const end = lastNumber.substr(0, lastNumber.length - ctx.length) + ctx;
          num = `SP.${date.getFullYear()}.${date.getMonth() + 1}.${end}`;
        }
        context.commit("SET_LAST_FORM_NUMBER", num);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getVoucherNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/voucher-number`);
      if (response.status === 200) {
        context.commit("SET_VOUCHER_NUMBER", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll(context) {
    try {
      const response = await api.get(`${BASE_URL}`);
      if (response.status === 200) {
        context.commit("SET_SALE_PAYMENTS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getApproval(context) {
    try {
      const response = await api.get(`${BASE_URL}/approval`);
      if (response.status === 200) {
        context.commit("SET_SALE_PAYMENTS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, salePaymentId) {
    try {
      const response = await api.get(`${BASE_URL}/${salePaymentId}`);
      // if (response.status === 200) {
      //   context.commit("SET_SALE_PAYMENT", response.data);
      // }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async queryPayment(context, queryPayment) {
    try {
      const response = await api.post(`${BASE_URL}/query`, queryPayment);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.resolve(error);
    }
  },
  async delete(context, params) {
    try {
      const response = await api.deleted(`${BASE_URL}/${params.id}`, { remark: params.remark });
      if (response.status === 200) {
        route.push({ name: "Sale Payment" });
      }
    } catch (error) {
      return Promise.resolve(error);
    }
  },
  async getListInvoice(context, customerId) {
    try {
      const response = await api.get(`${BASE_URL}/list-invoice/${customerId}`);
      // context.commit("SET_LIST_INVOICE", response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getInvoiceForRemark(context, customerId) {
    try {
      const response = await api.get(`${BASE_URL}/list-invoice/remark/${customerId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getListInvoiceById(context, customerId) {
    try {
      const response = await api.get(`${BASE_URL}/list-invoice/by/${customerId}`);
      // context.commit("SET_LIST_INVOICE", response.data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getVoucher(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/voucher/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LAST_FORM_NUMBER(state, payload) {
    state.lastFormNumber = payload;
  },
  SET_VOUCHER_NUMBER(state, payload) {
    state.voucherNumber = payload;
  },
  SET_SALE_PAYMENT(state, payload) {
    state.salePayment = payload;
  },
  SET_SALE_PAYMENTS(state, payload) {
    state.salePayments = payload;
  },
  SET_LIST_INVOICE(state, payload) {
    state.listInvoice = payload;
  },
  SET_LIST_INVOICE_DP(state, payload) {
    state.listInvoiceDp = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
