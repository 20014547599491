import api from "@/services/api";
import { Promise } from "core-js";

const BASE_URL = "/external";

//#region state
const state = {
  vendors: [],
  forwarders: [],
  purchaseOrders: [],
  deliveryOrders: [],
  customers: [],
  salePurchaseOrders: [],
  saleDeliveryOrders: [],
  employees: [],
  sites: [],
  npwps: [],
  contacts: [],
  inventory: [],
  sales: {},
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async getVendors(context) {
    try {
      const response = await api.get(`${BASE_URL}/purchase/vendors`);
      if (response.status === 200) {
        context.commit("SET_VENDORS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getForwarders(context) {
    try {
      const response = await api.get(`${BASE_URL}/purchase/forwarders`);
      if (response.status === 200) {
        context.commit("SET_FORWARDERS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getInventory() {
    try {
      const response = await api.get(`${BASE_URL}/inventory`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateInventory(context, item) {
    try {
      const response = await api.post(`${BASE_URL}/inventory/update`, item);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPurchaseOrders(context, vendorId) {
    try {
      const response = await api.get(`${BASE_URL}/purchase/purchase-orders/${vendorId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPurchaseOrderProducts(context, poId) {
    try {
      const response = await api.get(`${BASE_URL}/purchase/purchase-orders/products/${poId}`);
      // if (response.status === 200) {
      //   context.commit("SET_PURCHASE_ORDERS", response.data);
      // }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getVendorAddress(context, vendorId) {
    try {
      const response = await api.get(`${BASE_URL}/purchase/vendor/address/${vendorId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPurchaseOrdersById(context, params) {
    try {
      const response = await api.get(
        `${BASE_URL}/purchase/purchase-orders/by/${params.id}`,
        params
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDeliveryOrders(context, vendorId) {
    try {
      const response = await api.get(`${BASE_URL}/purchase/delivery-orders/${vendorId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDeliveryOrderAll() {
    try {
      const response = await api.get(`${BASE_URL}/purchase/delivery-orders`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDeliveryOrderDetail(context, rrId) {
    try {
      const response = await api.get(`${BASE_URL}/purchase/delivery-orders/detail/${rrId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDeliveryOrdersById(context, params) {
    try {
      const response = await api.get(
        `${BASE_URL}/purchase/delivery-orders/by/${params.id}`,
        params
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getCustomers(context) {
    try {
      const response = await api.get(`${BASE_URL}/sale/customers`);
      if (response.status === 200) {
        context.commit("SET_CUSTOMERS", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSalesDetail(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/sales/${id}`);
      if (response.status === 200) {
        context.commit("SET_SALES", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSalePurchaseOrders(context, customerId) {
    try {
      const response = await api.get(`${BASE_URL}/sale/purchase-orders/${customerId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSalePurchaseOrderAll(context, all) {
    try {
      const response = await api.get(`${BASE_URL}/sale/purchase-orders`, { all: all });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSalePurchaseOrderAllV2() {
    try {
      const response = await api.get(`${BASE_URL}/sale/purchase-orders/v2`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSalePurchaseOrderById(context, poId) {
    try {
      const response = await api.get(`${BASE_URL}/v2/sale/purchase-orders/detail/${poId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSaleDeliveryOrders(context, purchaseOrderId) {
    try {
      const response = await api.get(`${BASE_URL}/sale/delivery-orders/${purchaseOrderId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSaleDeliveryOrderForLinkDO(context, purchaseOrderId) {
    try {
      const response = await api.get(`${BASE_URL}/sale/delivery-orders/link-do/${purchaseOrderId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSaleDeliveryOrdersAll() {
    try {
      const response = await api.get(`${BASE_URL}/sale/delivery-orders`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSite(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/sale/site/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSiteAll() {
    try {
      const response = await api.get(`${BASE_URL}/sale/site/all`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSaleDeliveryOrdersDetail(context, deliveryOrderId) {
    try {
      const response = await api.get(`${BASE_URL}/sale/delivery-orders/detail/${deliveryOrderId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSaleDeliveryOrdersDetailV2(context, deliveryOrderId) {
    try {
      const response = await api.get(
        `${BASE_URL}/sale/delivery-orders/detail/v2/${deliveryOrderId}`
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getContact(context, accountId) {
    try {
      const response = await api.get(`${BASE_URL}/sale/contacts/${accountId}`);
      if (response.status === 200) {
        context.commit("SET_SALE_CONTACT", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSpk() {
    try {
      const response = await api.get(`${BASE_URL}/spk`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSpkById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/spk/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSalePurchaseOrdersById(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/sale/purchase-orders/by/${params.id}`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSaleDeliveryOrdersById(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/sale/delivery-orders/by/${params.id}`, params);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSaleBillToById(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/sale/bill-to/by/${params.id}`, params);
      if (response.status === 200) {
        context.commit("SET_SALE_BILL_TO", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSaleBillToContactById(context, params) {
    try {
      const response = await api.get(`${BASE_URL}/sale/bill-to-contact/by/${params.id}`, params);
      if (response.status === 200) {
        context.commit("SET_SALE_BILL_TO_CONTACT", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getEmployees(context) {
    try {
      const response = await api.get(`${BASE_URL}/employees`);
      if (response.status === 200) {
        context.commit("SET_EMPLOYEES", response.data);
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSites(context) {
    try {
      const response = await api.get(`${BASE_URL}/sites`);
      if (response.status === 200) {
        context.commit("SET_SITES", response.data);
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getNpwps(context) {
    try {
      const response = await api.get(`${BASE_URL}/npwps`);
      if (response.status === 200) {
        context.commit("SET_NPWPS", response.data);
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateNpwp(context, body) {
    try {
      const response = await api.put(`${BASE_URL}/npwps/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getNpwpById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/npwps/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async exportExcel() {
    try {
      const response = await api.get(`${BASE_URL}/export`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async exportInventory() {
    try {
      const response = await api.get(`${BASE_URL}/inventory/excel`, {}, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async exportVendor() {
    try {
      const response = await api.get(`${BASE_URL}/purchase/vendors/excel`, {}, "blob");
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTotalRr(context, rrId) {
    try {
      const response = await api.get(`${BASE_URL}/purchase/delivery-orders/total/${rrId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getProductByRrId(context, rrId) {
    try {
      const response = await api.get(`${BASE_URL}/purchase/delivery-orders/product/${rrId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateVendor(context, body) {
    try {
      const response = await api.post(`${BASE_URL}/purchase/vendors/${body.id}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getVendorById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/purchase/vendors/${id}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_VENDORS(state, payload) {
    state.vendors = payload;
  },
  SET_FORWARDERS(state, payload) {
    state.forwarders = payload;
  },
  SET_INVENTORY(state, payload) {
    state.inventory = payload;
  },
  SET_PURCHASE_ORDERS(state, payload) {
    state.purchaseOrders = payload;
  },
  SET_DELIVERY_ORDERS(state, payload) {
    state.deliveryOrders = payload;
  },
  SET_CUSTOMERS(state, payload) {
    state.customers = payload;
  },
  SET_SALE_PURCHASE_ORDERS(state, payload) {
    state.salePurchaseOrders = payload;
  },
  SET_SALE_DELIVERY_ORDERS(state, payload) {
    state.saleDeliveryOrders = payload;
  },
  SET_EMPLOYEES(state, payload) {
    state.employees = payload;
  },
  SET_SITES(state, payload) {
    state.sites = payload;
  },
  SET_NPWPS(state, payload) {
    state.npwps = payload;
  },
  SET_SALE_CONTACT(state, payload) {
    state.contacts = payload;
  },
  SET_SALES(state, payload) {
    state.sales = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
