<template>
  <v-app-bar absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down font-weight-light" v-text="$route.name" />

    <v-spacer />
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
    <v-divider vertical inset></v-divider>
    <v-list dense flat class="py-0" color="transparent" link>
      <v-list-item two-line>
        <v-list-item-avatar class="pr-0 mr-1">
          <v-menu bottom right offset-y origin="top right" transition="scale-transition">
            <template v-slot:activator="{ attrs, on }">
              <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>

            <v-list nav dense>
              <v-list-item @click="openDialog">
                <v-list-item-icon>
                  <v-icon>mdi-lock-reset</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Change Password</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ userDetail.name }}</v-list-item-title>
          <v-list-item-subtitle class="font-italic">{{
            userDetail.role ? userDetail.role.split("_")[1] : ""
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <dialog-change-password
      :dialog="dialog"
      @save="changePassword"
      @close="close"
    ></dialog-change-password>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import DialogChangePassword from "./DialogChangePassword.vue";
export default {
  name: "AppBar",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DialogChangePassword,
  },

  data: () => ({
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one",
    ],
    dialog: false,
  }),

  computed: {
    ...mapState(["drawer"]),
    ...mapState("auth", ["userDetail"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    logout() {
      this.$store.dispatch("auth/logout");
    },
    onConnect() {
      this.$store.dispatch("SUBSCRIBE_NOTIFICATION", `/topic/notification/${this.userDetail.id}`);
    },
    connect() {
      this.$store.dispatch("connect", this.onConnect);
    },
    disconnect() {
      this.$store.dispatch("disconnect");
    },
    openDialog() {
      this.dialog = true;
    },
    async changePassword(newPassword) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("user/changePassword", { newPassword: newPassword })
        .then(() => {
          this.logout();
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.dialog = false;
    },
  },
  mounted() {
    this.connect();
  },
};
</script>

<style scoped>
.v-list-item__icon .v-list-group__header__prepend-icon {
  margin-right: 16px !important;
}
</style>
