import api from "@/services/api";
import { Promise } from "core-js";

const BASE_URL = "/insurance";

//#region state
const state = {
  listInsurance: [],
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async dropdown(context) {
    try {
      const response = await api.get(`${BASE_URL}/dropdown`);
      if (response.status === 200) {
        context.commit("SET_LIST_INSURANCE", response.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LIST_INSURANCE(state, payload) {
    state.listInsurance = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
