import api from "@/services/api";
import { Promise } from "core-js";
import route from "@/router/index";

const BASE_URL = "/bank-statement";

//#region state
const state = {
  lastFormNumber: null,
};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async getLastFormNumber(context) {
    try {
      const response = await api.get(`${BASE_URL}/form-number`);
      if (response.status === 200) {
        let num = "";
        let date = new Date();
        if (response.data === "New") {
          num = `BS.${date.getFullYear()}.${date.getMonth() + 1}.00001`;
        } else {
          const lastNumber = response.data ? response.data.split(".")[3] : "";
          const ctx = "" + (parseInt(lastNumber) + 1);
          const end = lastNumber.substr(0, lastNumber.length - ctx.length) + ctx;
          num = `BS.${date.getFullYear()}.${date.getMonth() + 1}.${end}`;
        }
        context.commit("SET_LAST_FORM_NUMBER", num);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async create(context, body) {
    try {
      const response = await api.post(BASE_URL, body);
      if (response.status === 200) {
        route.push({ name: "Bank Statement" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async update(context, body) {
    try {
      const response = await api.put(`${BASE_URL}`, body);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async delete(context, id) {
    try {
      const response = await api.deleted(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        route.push({ name: "Bank Statement" });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAll() {
    try {
      const response = await api.get(BASE_URL);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(context, id) {
    try {
      const response = await api.get(`${BASE_URL}/${id}`);
      if (response.status === 200) {
        return Promise.resolve(response);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {
  SET_LAST_FORM_NUMBER(state, payload) {
    state.lastFormNumber = payload;
  },
};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
