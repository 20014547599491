import api from "@/services/api";
import { Promise } from "core-js";

const BASE_URL = "/buffer-invoice";

//#region state
const state = {};
//#endregion

//#region getters
const getters = {};
//#region

//#region actions
const actions = {
  async getBufferInvoices() {
    try {
      const response = await api.get(`${BASE_URL}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getBufferInvoice(context, bufferInvoiceId) {
    try {
      const response = await api.get(`${BASE_URL}/${bufferInvoiceId}`);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
//#endregion

//#region mutations
const mutations = {};
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
